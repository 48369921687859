export class UrlBuilder {
    static shareEventUrl(params: {
        apiUrl: string
        applicationId: string
        groupId: string
        eventId: string
        eventStart: Date
    }): string {
        const queryParams = new URLSearchParams({
            groupId: params.groupId,
            eventId: params.eventId,
            eventStart: params.eventStart.toISOString(),
        })

        return `${params.apiUrl}/share/${params.applicationId}?${queryParams.toString()}`
    }
}
