import { DateTime } from 'luxon'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { SessionRepository } from '../../authentication/SessionRepository'
import { WebAppHttpRepository } from '../../common/WebAppHttpRepository'
import { Event } from '../Event'
import {
    ChildEventDeletedEvent,
    EventAttendanceUpdatedEvent,
    EventCreatedEvent,
    EventDeletedEvent,
    EventUpdatedEvent,
    ThisAndFollowingEventDeletedEvent,
} from '../events'
import { CreateChildEventBody } from './CreateChildEventBody'
import { CreateEventBody } from './CreateEventBody'
import { SplitEventBody } from './SplitEventBody'
import { UpdateEventBody } from './UpdateEventBody'

export class EventRepository extends WebAppHttpRepository {
    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    async getEvents(
        params: {
            groupId?: string
            from?: string
        },
        abortSignal?: AbortSignal
    ): Promise<{ events: Event[]; nextEventStart: string }> {
        const fromParam = params.from ? `from=${params.from}` : ''
        const groupIdParam = params.groupId ? `groupId=${params.groupId}` : ''
        const questionMark = fromParam || groupIdParam ? '?' : ''
        const ampersand = fromParam && groupIdParam ? '&' : ''
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(
                `${this.apiUrl}/api/v3/events${questionMark}${fromParam}${ampersand}${groupIdParam}`,
                abortSignal
            )
        )

        return {
            events: response.events.map(Event.fromJSON),
            nextEventStart: response.nextEventStart,
        }
    }

    async getEvent(eventId: string, start?: string, abortSignal?: AbortSignal): Promise<Event> {
        const query = start ? `?start=${start}` : ''
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(`${this.apiUrl}/api/v3/events/${eventId}${query}`, abortSignal)
        )

        return Event.fromJSON(response)
    }

    async getAttendances(
        eventId: string,
        start: string,
        abortSignal?: AbortSignal
    ): Promise<EventAttendance[]> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any>(
                `${this.apiUrl}/api/v1/events/${eventId}/attendances?eventStart=${start}`,
                abortSignal
            )
        )

        return response.map(EventAttendance.fromJSON)
    }

    async createEvent(body: CreateEventBody): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v3/events`, body.toFormData())
        )

        return Event.fromJSON(response)
    }

    async createChildEvent(body: CreateChildEventBody, eventId: string): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v1/events/${eventId}/child`, body.toFormData())
        )

        const createdEvent = Event.fromJSON(response)

        document.dispatchEvent(new EventCreatedEvent(createdEvent))

        return createdEvent
    }

    async splitEvent(body: SplitEventBody, eventId: string): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v1/events/${eventId}/split`, body.toFormData())
        )

        const createdEvent = Event.fromJSON(response)

        document.dispatchEvent(new EventCreatedEvent(createdEvent))

        return createdEvent
    }

    async updateEvent(eventId: string, body: UpdateEventBody): Promise<Event> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.patch<any>(`${this.apiUrl}/api/v3/events/${eventId}`, body.toFormData())
        )

        const updatedEvent = Event.fromJSON(response)

        document.dispatchEvent(new EventUpdatedEvent(updatedEvent))

        return updatedEvent
    }

    async updateAttendance(params: {
        eventId: string
        eventStart: DateTime
        oldState: EventAttendanceState
        state: EventAttendanceState
    }) {
        try {
            document.dispatchEvent(
                new EventAttendanceUpdatedEvent(params.eventId, params.eventStart, params.state)
            )
            await this.sessionRepository.withAccessToken(async () =>
                this.post(`${this.apiUrl}/api/v1/events/${params.eventId}/attendances`, {
                    eventStart: params.eventStart.toISO(),
                    state: params.state,
                })
            )
        } catch (error) {
            document.dispatchEvent(
                new EventAttendanceUpdatedEvent(params.eventId, params.eventStart, params.oldState)
            )
            throw error
        }
    }

    async deleteEvent(eventId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v1/events/${eventId}`)
        )

        document.dispatchEvent(new EventDeletedEvent(eventId))
    }

    async deleteChildEvent(eventId: string, excludeStartDates: DateTime[]) {
        await this.sessionRepository.withAccessToken(async () =>
            this.patch(`${this.apiUrl}/api/v1/events/${eventId}/child`, {
                excludeStartDates,
            })
        )

        document.dispatchEvent(new ChildEventDeletedEvent(eventId, excludeStartDates))
    }

    async deleteThisAndFollowingEvents(eventId: string, from: DateTime) {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(
                `${this.apiUrl}/api/v1/events/${eventId}?from=${from.toJSDate().toISOString()}`
            )
        )

        document.dispatchEvent(new ThisAndFollowingEventDeletedEvent(eventId, from))
    }
}
