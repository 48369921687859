import { Post } from '../../posts/Post'
import { PostListItemContainer } from '../PostListComponents'
import { UnsupportedContentView } from './UnsupportedContentView'

interface Properties {
    isReadOnly?: boolean
    hasWhiteBackground?: boolean
    post: Post
}

export const PostFundraiserView = ({ post, ...properties }: Properties) => {
    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const fundraiser = post.fundraiser

    if (!fundraiser) {
        return <></>
    }

    return (
        <PostListItemContainer>
            <UnsupportedContentView
                isReadOnly={properties.isReadOnly}
                hasWhiteBackground={hasWhiteBackground}
            />
        </PostListItemContainer>
    )
}
