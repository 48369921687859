import { Pdf } from 'shared/lib/models/Pdf'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { DateTime } from 'luxon'

export class CreateChildEventBody {
    constructor(
        readonly title: string,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly description: string,
        readonly location: string,
        readonly pdfs: Pdf[],
        readonly excludeStartDates: DateTime[],
        readonly localPdfs: LocalPdf[] | undefined,
        readonly canMembersRegisterAttendance: boolean
    ) {}

    public toFormData() {
        const formData = new FormData()
        const pdfs = this.pdfs
        const localPdfs = this.localPdfs
        formData.append('title', this.title)
        formData.append('start', this.start.toString())
        formData.append('end', this.end.toString())
        formData.append('isAllDay', `${this.isAllDay}`)
        formData.append('canMembersRegisterAttendance', `${this.canMembersRegisterAttendance}`)
        formData.append('description', this.description)
        formData.append('location', this.location)
        localPdfs?.forEach((pdf) => {
            formData.append('pdfFiles', pdf.file)
            formData.append('pdfImageFiles', pdf.thumbnail.file)
        })

        if (pdfs.length !== 0) {
            pdfs.forEach((pdf) => formData.append('pdfKeys[]', pdf.key))
        }

        this.excludeStartDates.forEach((date) => {
            formData.append('excludeStartDates[]', date.toISO() ?? '')
        })

        return formData
    }
}
