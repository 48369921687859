import { Box, styled, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonWithCount } from 'shared/lib/components/buttons/ButtonWithCount'
import { useTranslation } from 'shared/lib/i18n'
import { DetailViewContainer } from '../../../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../../../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../../../common/detailView/DetailViewProperties'
import { useDetailView } from '../../../common/detailView/hooks'
import {
    AttendanceListItemViewModel,
    AttendanceListLoadingViewModel,
} from './AttendanceListViewModels'
import { AttendancesList } from './AttendancesList'
import { useAttendances } from './hooks'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { useState } from 'react'

interface Properties extends DetailViewProperties {
    eventId: string
}

export const AttendancesListView = ({ eventId, closeIcon, onCloseClicked }: Properties) => {
    const translations = useTranslation()
    const [searchParams] = useSearchParams()

    const eventStart = searchParams.get('eventStart')!

    const { isDetailViewDisplayedAsPopup: isDetailViewOpenAsPopup } = useDetailView()
    const { isLoading, error, attendances } = useAttendances(eventId, eventStart)

    const [selectedState, setSelectedState] = useState(EventAttendanceState.ATTENDING)

    const attendees = attendances.filter(
        (attendance) => attendance.state === EventAttendanceState.ATTENDING
    )
    const notAttending = attendances.filter(
        (attendance) => attendance.state === EventAttendanceState.ABSENT
    )
    const maybeAttending = attendances.filter(
        (attendance) => attendance.state === EventAttendanceState.MAYBE
    )

    const selectedAttendances =
        selectedState === EventAttendanceState.ATTENDING
            ? attendees
            : selectedState === EventAttendanceState.ABSENT
              ? notAttending
              : maybeAttending

    const viewModels = isLoading
        ? Array.from({ length: 10 }, () => new AttendanceListLoadingViewModel())
        : selectedAttendances.map((attendance) => new AttendanceListItemViewModel(attendance))

    const getEmptyStateMessage = () => {
        switch (selectedState) {
            case EventAttendanceState.ATTENDING:
                return translations('zero_attending_attendees')
            case EventAttendanceState.ABSENT:
                return translations('zero_absent_attendees')
            default:
                return translations('zero_maybe_attendees')
        }
    }

    const onStateSelected = (
        event: React.MouseEvent<HTMLDivElement>,
        state: EventAttendanceState
    ) => {
        event.currentTarget.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        })
        setSelectedState(state)
    }

    return (
        <DetailViewContainer
            onCloseClicked={onCloseClicked}
            closeIcon={closeIcon}
            title={translations('attendees')}
            contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
        >
            <Box height="100%">
                <AttendanceStatesContainer>
                    <Box />
                    <ButtonWithCount
                        isSelected={selectedState === EventAttendanceState.ATTENDING}
                        title={translations('attending')}
                        count={attendees.length}
                        onClick={(event) => onStateSelected(event, EventAttendanceState.ATTENDING)}
                    />
                    <ButtonWithCount
                        isSelected={selectedState === EventAttendanceState.ABSENT}
                        title={translations('absent')}
                        count={notAttending.length}
                        onClick={(event) => onStateSelected(event, EventAttendanceState.ABSENT)}
                    />
                    <ButtonWithCount
                        isSelected={selectedState === EventAttendanceState.MAYBE}
                        title={translations('maybe')}
                        count={maybeAttending.length}
                        onClick={(event) => onStateSelected(event, EventAttendanceState.MAYBE)}
                    />
                    <Box />
                </AttendanceStatesContainer>

                {viewModels.length === 0 && (
                    <Typography variant="body2" mt={4}>
                        {getEmptyStateMessage()}
                    </Typography>
                )}

                {viewModels.length > 0 && <AttendancesList attendances={viewModels} />}
            </Box>

            <ErrorHandler
                error={error}
                translations={translations}
                horizontal={isDetailViewOpenAsPopup ? 'center' : 'right'}
            />
        </DetailViewContainer>
    )
}

const AttendanceStatesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(2),
    overflow: 'scroll',
}))
