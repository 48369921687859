import { Box, styled } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { AvatarProperties } from 'shared/lib/components/Avatar'
import { HorizontalDivider } from 'shared/lib/components/Divider'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { errorColor } from 'shared/lib/theme/Theme'
import { ReactComponent as GroupIcon } from '../assets/svg/group.svg'
import SessionContext from '../authentication/SessionContextProvider'
import {
    DescriptionTextView,
    DetailViewContainer,
    StyledAvatar,
    TitleTextView,
} from '../common/detailView'
import { DetailViewProperties } from '../common/detailView/DetailViewProperties'
import { useDetailView } from '../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, groupRepository, notificationRepository } from '../index'
import { Post } from '../posts/Post'
import { Paths } from '../routing/paths'
import { ShareButton } from '../share/ShareButton'
import { UserDetailLoadingState } from '../user/userDetail/UserDetailLoadingState'
import { GroupMemberState } from './Group'
import { GroupType } from 'shared/lib/groups/GroupType'
import { EditGroupPopup } from './createOrEditGroupPopup/EditGroupPopup'
import { GroupActionsButton } from './detail/GroupActionsButton'
import { GroupDetailMemberListLoadingState } from './detail/GroupDetailMemberListLoadingState'
import { GroupDetailMembersList } from './detail/GroupDetailMembersList'
import {
    GroupAccessRequestedEvent,
    GroupAccessWithdrawnEvent,
    GroupEventKey,
    GroupUpdatedEvent,
} from './events'
import { useGroup, useGroupMembers } from './hooks'
import { UnapprovedGroupMembersList } from './members/UnapprovedGroupMembersList'
import { GroupMember } from './members/GroupMember'
import { delay } from 'shared/lib/utils/Utils'
import { DEFAULT_ANIMATION_DURATION } from 'shared/lib/common/Constants'
import { FullScreenMediaContext } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { MediaItem } from 'shared/lib/models/MediaItem'

interface Properties extends DetailViewProperties {
    canOpenNewDetailView: boolean
    groupId: string
    onUserSelected(userId: string): void
}

export const GroupDetailView = ({ groupId, ...properties }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const { openFullscreenMedia } = useContext(FullScreenMediaContext)!

    const { isDetailViewDisplayedAsPopup: isDetailViewOpenAsPopup } = useDetailView()
    const {
        isLoading: isGroupLoading,
        error: groupError,
        group,
        getGroup,
        setGroup,
    } = useGroup(groupId)
    const {
        groupMembers,
        isLoading: isLoadingGroupMembers,
        getGroupMembers,
    } = useGroupMembers(groupId)

    const signedInUser = useContext(SessionContext)!.getSignedInUser()

    const [error, setError] = useState<string | undefined>(undefined)
    const [approvedGroupMembers, setApprovedGroupMembers] = useState<GroupMember[]>([])
    const [isRequestAccessLoading, setIsRequestAccessLoading] = useState<boolean>(false)
    const [isLeavingGroup, setIsLeavingGroup] = useState<boolean>(false)
    const [groupIdToLeave, setGroupIdToLeave] = useState<string | undefined>(undefined)
    const [isDeletingGroup, setIsDeletingGroup] = useState<boolean>(false)
    const [groupIdToDelete, setGroupIdToDelete] = useState<string | undefined>(undefined)
    const [isEditingGroup, setIsEditingGroup] = useState<boolean>(false)

    const groupMemberState = group?.groupMemberState
    const isGroupMember = groupMemberState === GroupMemberState.APPROVED
    const isOnTimeLinePage = location.pathname.includes(
        Paths.GROUP_TIME_LINE.replace(':groupId?', groupId)
    )

    const onGroupImageClick = () => {
        if (!group?.image) {
            return
        }
        openFullscreenMedia(0, MediaItem.mapToMediaItem(group.image, fileRepository))
    }

    const getAccessButtonTitle = () => {
        switch (groupMemberState) {
            case GroupMemberState.PENDING:
                return translations('withdraw_request')
            case GroupMemberState.NONE:
                return group?.type === GroupType.INVITATION_ONLY
                    ? translations('request_access')
                    : translations('become_member')
        }
    }

    const onAccessButtonClicked = () => {
        setIsRequestAccessLoading(true)

        if (groupMemberState === GroupMemberState.PENDING) {
            withdrawAccess()
        } else {
            requestAccess()
        }
    }

    const requestAccess = () => {
        Promise.all([
            groupRepository.requestAccess(group?.id || groupId),
            delay(DEFAULT_ANIMATION_DURATION),
        ])
            .then(([updatedGroup]) => {
                if (updatedGroup.type === GroupType.START && signedInUser) {
                    setApprovedGroupMembers((prevState) => [
                        ...prevState,
                        GroupMember.fromSignedInUser(signedInUser),
                    ])
                }
            })
            .catch(setError)
            .finally(() => setIsRequestAccessLoading(false))
    }

    const withdrawAccess = () => {
        setIsRequestAccessLoading(true)

        Promise.all([
            groupRepository.withdrawAccessRequest(group?.id || groupId),
            delay(DEFAULT_ANIMATION_DURATION),
        ])
            .catch(setError)
            .finally(() => setIsRequestAccessLoading(false))
    }

    const onGroupLeaveClicked = async () => {
        setIsLeavingGroup(true)

        try {
            await groupRepository.leaveGroup(group?.id || groupId)
            redirectToMyChurchOrCloseView()
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLeavingGroup(false)
        }
    }

    const onGroupDeleteClicked = async () => {
        setIsDeletingGroup(true)

        try {
            await groupRepository.deleteGroup(group?.id || groupId)
            redirectToMyChurchOrCloseView()
        } catch (error: any) {
            setError(error)
        } finally {
            setIsDeletingGroup(false)
        }
    }

    const redirectToMyChurchOrCloseView = () => {
        switch (true) {
            case location.pathname.includes(Paths.TIME_LINE):
                navigate(Paths.TIME_LINE, { replace: true })
                break
            case location.pathname.includes(Paths.EVENTS):
                navigate(Paths.EVENTS, { replace: true })
                break
            default:
                properties.onCloseClicked()
        }
    }

    const navigateToGroupOnTimeline = (post: Post) => {
        navigate(Paths.GROUP_TIME_LINE.replace(':groupId', post.groupId), { replace: true })
    }

    const onGroupUpdated = useCallback(
        (event: Event) => {
            const updatedGroupId = (event as GroupUpdatedEvent).detail.id

            if (updatedGroupId === groupId) {
                getGroup()
            }
        },
        [groupId, getGroup]
    )

    const onAccessRequestedOrWithdrawn = useCallback(
        (event: Event) => {
            if (
                event instanceof GroupAccessRequestedEvent ||
                event instanceof GroupAccessWithdrawnEvent
            ) {
                setGroup(event.detail.group)
            }
        },
        [setGroup]
    )

    useEffect(() => {
        setApprovedGroupMembers(groupMembers.filter((groupMember) => groupMember.isApproved))
    }, [groupMembers])

    useEffect(() => {
        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        document.addEventListener(
            GroupEventKey.GROUP_ACCESS_REQUESTED,
            onAccessRequestedOrWithdrawn
        )
        document.addEventListener(
            GroupEventKey.GROUP_ACCESS_WITHDRAWN,
            onAccessRequestedOrWithdrawn
        )

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
            document.removeEventListener(
                GroupEventKey.GROUP_ACCESS_REQUESTED,
                onAccessRequestedOrWithdrawn
            )
            document.removeEventListener(
                GroupEventKey.GROUP_ACCESS_WITHDRAWN,
                onAccessRequestedOrWithdrawn
            )
        }
    }, [onGroupUpdated, getGroupMembers, onAccessRequestedOrWithdrawn])

    useEffect(() => {
        if (group?.isStartGroup) {
            getGroupMembers()
        }
    }, [group?.isStartGroup, getGroupMembers])

    useEffect(() => {
        notificationRepository.markAllGroupNotificationsAsRead(groupId)
    }, [groupId])

    if (isGroupLoading || isLoadingGroupMembers) {
        return (
            <DetailViewContainer
                onCloseClicked={properties.onCloseClicked}
                closeIcon={properties.closeIcon}
            >
                <Container>
                    <UserDetailLoadingState />
                    <Divider />
                    <GroupDetailMemberListLoadingState />
                </Container>
            </DetailViewContainer>
        )
    }

    return (
        <DetailViewContainer
            onCloseClicked={properties.onCloseClicked}
            closeIcon={properties.closeIcon}
            overflow="auto"
            actionButton={
                group && !group.isHome && (isGroupMember || group.canEdit) ? (
                    <GroupActionsButton
                        canEditGroup={group.canEdit}
                        onEditGroupClicked={() => setIsEditingGroup(true)}
                        onLeaveButtonClicked={() => setGroupIdToLeave(groupId)}
                        onDeleteButtonClicked={() => setGroupIdToDelete(groupId)}
                    />
                ) : undefined
            }
            shareButton={
                group && (
                    <ShareButton group={group} onSuccessfullyShared={navigateToGroupOnTimeline} />
                )
            }
        >
            <Container>
                <GroupImageView
                    src={fileRepository.getImageUrl(group?.image)}
                    onClick={onGroupImageClick}
                    sx={{ cursor: !!group?.image ? 'pointer' : 'default' }}
                ></GroupImageView>

                <TitleTextView>{group?.name}</TitleTextView>

                {group?.description && (
                    <DescriptionTextView>{group.description}</DescriptionTextView>
                )}

                {isGroupMember && !isOnTimeLinePage && (
                    <ActionButton
                        onClick={() => navigate(Paths.GROUP_TIME_LINE.replace(':groupId', groupId))}
                    >
                        {translations('go_to_group')}
                    </ActionButton>
                )}

                {!isGroupMember && (
                    <ActionButton
                        isLoading={isRequestAccessLoading}
                        onClick={onAccessButtonClicked}
                    >
                        {getAccessButtonTitle()}
                    </ActionButton>
                )}

                <Divider />

                {group?.canEdit && (
                    <UnapprovedGroupMembersList
                        groupId={groupId}
                        groupMembers={groupMembers}
                        showTitle={true}
                        bottomDivider={<Divider />}
                        maxNumberOfMembersToShow={5}
                    />
                )}

                {group && (
                    <GroupDetailMembersList
                        group={group}
                        groupMembers={approvedGroupMembers}
                        onUserSelected={properties.onUserSelected}
                        canOpenNewDetailView={properties.canOpenNewDetailView}
                    />
                )}

                {group?.canEdit && isEditingGroup && (
                    <EditGroupPopup group={group} onCloseClicked={() => setIsEditingGroup(false)} />
                )}

                {group && (
                    <AlertDialog
                        isLoading={isLeavingGroup}
                        isVisible={!!groupIdToLeave}
                        titleIcon={<AlertIcon fill={errorColor} />}
                        title={translations('leave_group_message', [group?.name])}
                        cancelButtonTitle={translations('cancel')}
                        onCancelButtonClicked={() => setGroupIdToLeave(undefined)}
                        continueButtonType={ButtonType.RED}
                        continueButtonTitle={translations('leave_group')}
                        onContinueButtonClicked={onGroupLeaveClicked}
                    />
                )}

                {group && (
                    <AlertDialog
                        isLoading={isDeletingGroup}
                        isVisible={!!groupIdToDelete}
                        titleIcon={<AlertIcon fill={errorColor} />}
                        title={translations('delete_group_message', [group?.name])}
                        cancelButtonTitle={translations('cancel')}
                        onCancelButtonClicked={() => setGroupIdToDelete(undefined)}
                        continueButtonType={ButtonType.RED}
                        continueButtonTitle={translations('delete_group')}
                        onContinueButtonClicked={onGroupDeleteClicked}
                    />
                )}

                <ErrorHandler
                    error={error || groupError}
                    translations={translations}
                    horizontal={isDetailViewOpenAsPopup ? 'center' : 'right'}
                />
            </Container>
        </DetailViewContainer>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
}))

const Divider = styled((props) => <HorizontalDivider {...props} />)(({ theme }) => ({
    width: `calc(100% - ${theme.spacing(4)})`,
    margin: theme.spacing(3, 2),
}))

const GroupImageView = styled((props: Omit<AvatarProperties, 'children'>) => (
    <StyledAvatar {...props}>
        <GroupIcon fill="white" />
    </StyledAvatar>
))(() => ({
    '& svg': {
        width: '50%',
        height: '50%',
    },
}))

const ActionButton = styled(PrimaryContainedButton)(({ theme }) => ({
    marginTop: theme.spacing(2),
}))
