export enum NotificationType {
    COMMENT_ON_POST = 'COMMENT_ON_POST',
    EVENT_ATTENDANCE_UPDATED = 'EVENT_ATTENDANCE_UPDATED',
    POST_LIKED = 'POST_LIKED',
    POST_PLACED_IN_GROUP = 'POST_PLACED_IN_GROUP',
    USER_ACCESS_DENIED_TO_GROUP = 'USER_ACCESS_DENIED_TO_GROUP',
    USER_ACCESS_GRANTED_TO_GROUP = 'USER_ACCESS_GRANTED_TO_GROUP',
    USER_ADDED_TO_GROUP = 'USER_ADDED_TO_GROUP',
    USER_APPROVED = 'USER_APPROVED',
    USER_REGISTERED = 'USER_REGISTERED',
    USER_REQUESTED_ACCESS_TO_GROUP = 'USER_REQUESTED_ACCESS_TO_GROUP',
}
