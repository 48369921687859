import { Box, styled, Typography } from '@mui/material'
import { Post } from '../../posts/Post'
import { grey_3, spacing } from 'shared/lib/theme/Theme'
import { PostTopBarView } from './PostTopBarView'
import { MessageView } from '../../components/message/MessageView'
import { PostMediaView } from './PostMediaView'
import { PostPdfView } from './PostPdfView'
import { PostLinkPreviewView } from './PostLinkPreviewView'
import { PostCharitiesView } from './PostCharitiesView'
import { PostFundraiserView } from './PostFundraiserView'
import { PostDiscoverGroupsView } from './PostDiscoverGroupsView'
import { PostSharedGroupView } from './PostSharedGroupView'
import { useTranslation } from 'shared/lib/i18n'
import { PostListItemContainer } from '../PostListComponents'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { PostSharedEventView } from './PostSharedEventView.tsx'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { SharedEvent } from '../../posts/SharedEvent'

export interface Properties {
    isEditing?: boolean
    disableDeleteButton?: boolean
    width: number
    parentMargins: number
    sharedPostId?: string
    sharedPost?: Post

    onSharedEventClicked?(sharedEvent: SharedEvent): void
    onEventAttendButtonClicked?(state: EventAttendanceState): void
    onDeleteButtonClicked?(): void
}

export const PostSharedPostView = ({ sharedPost, ...properties }: Properties) => {
    const translations = useTranslation()

    const paddingLeftAndRight = properties.parentMargins + spacing * 4

    if (!properties.sharedPostId) {
        return <></>
    }

    if (!sharedPost) {
        return (
            <PostListItemContainer>
                <DeletedGroupContainer>
                    <Typography variant="body1">
                        {translations('shared_post_has_been_deleted')}
                    </Typography>
                    {properties.onDeleteButtonClicked && (
                        <FilledCloseButtonWithWhiteCross
                            disabled={properties.disableDeleteButton}
                            onClick={() => {
                                properties.onDeleteButtonClicked &&
                                    properties.onDeleteButtonClicked()
                            }}
                        />
                    )}
                </DeletedGroupContainer>
            </PostListItemContainer>
        )
    }

    return (
        <PostListItemContainer isEditing={properties.isEditing}>
            <Container>
                <PostTopBarView
                    isEditable={false}
                    isUnreadable={false}
                    isCreatorClickable={false}
                    isGroupNameVisible={true}
                    isGroupNameClickable={false}
                    post={sharedPost}
                    onCloseButtonClicked={properties.onDeleteButtonClicked}
                    px={2}
                    pt={2}
                />
                <MessageView details={sharedPost} mx={2} mt={2} backgroundColor={grey_3} />
                <PostMediaView
                    width={properties.width}
                    margins={paddingLeftAndRight}
                    post={sharedPost}
                />
                <PostPdfView hasWhiteBackground={true} post={sharedPost} />
                <PostLinkPreviewView hasWhiteBackground={true} post={sharedPost} />
                <PostCharitiesView
                    isReadOnly={properties.isEditing}
                    hasWhiteBackground={true}
                    post={sharedPost}
                />
                <PostFundraiserView
                    isReadOnly={properties.isEditing}
                    hasWhiteBackground={true}
                    post={sharedPost}
                />
                <PostDiscoverGroupsView
                    isReadOnly={properties.isEditing}
                    hasWhiteBackground={true}
                    post={sharedPost}
                />
                <PostSharedGroupView
                    hasWhiteBackground={true}
                    sharedGroup={sharedPost.sharedGroup}
                />
                <PostSharedEventView
                    isReadOnly={properties.isEditing}
                    hasWhiteBackground={true}
                    sharedEvent={sharedPost.sharedEvent}
                    onSharedEventClicked={properties.onSharedEventClicked}
                    onEventAttendButtonClicked={properties.onEventAttendButtonClicked}
                />
            </Container>
        </PostListItemContainer>
    )
}

const DeletedGroupContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: grey_3,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const Container = styled(Box)(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    backgroundColor: grey_3,
    paddingBottom: theme.spacing(2),
}))
