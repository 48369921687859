import { Box, styled } from '@mui/material'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef } from 'react'
import { HorizontalDivider } from 'shared/lib/components/Divider'
import { Popup } from 'shared/lib/components/Popup'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { TextFieldWithNoBorders } from 'shared/lib/components/textfields/TextFieldWithNoBorders'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { Pdf } from 'shared/lib/models/Pdf'
import { grey_2 } from 'shared/lib/theme/Theme'
import { ReactComponent as DescriptionIcon } from '../../../src/assets/svg/description.svg'
import { ReactComponent as LocationIcon } from '../../../src/assets/svg/location_outlined.svg'
import { ReactComponent as AttendanceIcon } from 'shared/lib/assets/svg/group_outlined.svg'
import { useDetailView } from '../../common/detailView/hooks'
import { Group, MinimalGroupInfo } from '../../groups/Group'
import { GroupSelector } from '../../groups/groupselector/GroupSelector'
import { useTranslation } from 'shared/lib/i18n'
import { EventDurationView } from './EventDurationView'
import { EventPdfsView } from './EventPdfsView'
import { grey_3 } from 'shared/lib/theme/constants'
import { RecurrenceRule } from 'shared/lib/recurring/RecurrenceRule'
import { SwitchRow } from 'shared/lib/components/switch/SwitchRow'

export type EventPopupEvent = {
    title: string
    description: string
    location: string
    start: DateTime
    end: DateTime
    isAllDay: boolean
    recurrenceRule: RecurrenceRule | undefined
    canMembersRegisterAttendance: boolean
    pdfs: Pdf[]
    selectedPdfs: LocalPdf[]
}

interface Properties {
    canSelectGroup: boolean
    canContinue: boolean
    canMakeRecurring: boolean
    isLoading: boolean
    mode: 'creating' | 'editing'
    event: EventPopupEvent
    groups?: Group[]
    selectedGroup?: MinimalGroupInfo
    continueButtonTitle: string

    onFieldChanged: <T extends keyof EventPopupEvent>(
        field: T
    ) => (value: EventPopupEvent[T]) => void
    onCloseButtonClicked: () => void
    onSaveButtonClicked: (groupSelectorRef: HTMLDivElement) => void
    onPdfDeleted: (pdf: Pdf | LocalPdf) => void
    onGroupSelected?: (group: Group) => void
}

export const EventPopup = ({ event, onFieldChanged, ...properties }: Properties) => {
    const translations = useTranslation()
    const groupSelectorRef = useRef<HTMLDivElement>(null)

    const { isDetailViewDisplayedAsPopup } = useDetailView()

    const groups = properties.groups || []
    const canCreateEventsGroups = groups.filter(
        (group) => group.canCreateEvents && !group.isMyChurch
    )

    const onPdfsSelected = useCallback(
        (pdfs: LocalPdf[]) => {
            onFieldChanged('selectedPdfs')(pdfs)
        },
        [onFieldChanged]
    )

    const onKeyUpButtonClicked = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            properties.onCloseButtonClicked()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        document.addEventListener('keyup', onKeyUpButtonClicked, false)

        return () => {
            document.removeEventListener('keyup', onKeyUpButtonClicked, false)
        }
    }, [onKeyUpButtonClicked])

    return (
        <>
            <Popup isVisible={true} withBackdrop={!isDetailViewDisplayedAsPopup}>
                <Box p={3} display={'flex'} alignItems={'center'}>
                    <GroupSelector
                        groups={canCreateEventsGroups}
                        ref={groupSelectorRef}
                        selectedGroup={properties.selectedGroup}
                        onGroupSelected={properties.onGroupSelected}
                        isEnabled={properties.canSelectGroup && !properties.isLoading}
                    />
                    <FilledCloseButtonWithWhiteCross onClick={properties.onCloseButtonClicked} />
                </Box>

                <HorizontalDivider />

                <ContentContainer>
                    <TextFieldWithNoBorders
                        maxLength={75}
                        autoFocus={true}
                        fontSize="24px"
                        textColor={grey_2}
                        placeholder={translations('agenda_enter_title')}
                        value={event.title}
                        sx={{
                            ml: -2,
                        }}
                        onChanged={onFieldChanged('title')}
                    />

                    <HorizontalDivider mb={4} />

                    <EventDurationView
                        isLoading={properties.isLoading}
                        isAllDay={event.isAllDay}
                        allowStartInPast={properties.mode === 'editing'}
                        canMakeRecurring={properties.canMakeRecurring}
                        startDate={event.start}
                        endDate={event.end}
                        recurrenceRule={event.recurrenceRule}
                        onStartDateChanged={onFieldChanged('start')}
                        onEndDateChanged={onFieldChanged('end')}
                        onIsAllDayChanged={onFieldChanged('isAllDay')}
                        onRecurrenceRuleChanged={onFieldChanged('recurrenceRule')}
                    />

                    <HorizontalDivider mt={4} mb={3} />

                    <Box display="flex" alignItems="center">
                        <AttendanceIcon />

                        <Box ml={2} flexGrow={1}>
                            <SwitchRow
                                mt={8}
                                title={translations('member_event_attendance_registration')}
                                isChecked={event.canMembersRegisterAttendance}
                                onChanged={onFieldChanged('canMembersRegisterAttendance')}
                                isDisabled={properties.isLoading}
                            />
                        </Box>
                    </Box>

                    <HorizontalDivider mt={3} mb={4} />

                    <Box display="flex" alignItems="center">
                        <DescriptionIcon />

                        <TextFieldWithNoBorders
                            multiline={true}
                            height="52px"
                            placeholder={translations('description')}
                            value={event.description}
                            onChanged={onFieldChanged('description')}
                            disabled={properties.isLoading}
                            backgroundColor={grey_3}
                            borderRadius="16px"
                            sx={{
                                ml: 2,
                                borderRadius: '16px',
                            }}
                        />
                    </Box>

                    <Box display="flex" alignItems="center" mt={2}>
                        <LocationIcon />
                        <TextFieldWithNoBorders
                            maxLength={320}
                            height="52px"
                            placeholder={translations('location')}
                            value={event.location}
                            onChanged={onFieldChanged('location')}
                            disabled={properties.isLoading}
                            backgroundColor={grey_3}
                            borderRadius="16px"
                            sx={{
                                ml: 2,
                                borderRadius: '16px',
                            }}
                        />
                    </Box>

                    <HorizontalDivider my={4} />

                    <EventPdfsView
                        pdfs={event.pdfs}
                        onDeletePdfButtonClicked={properties.onPdfDeleted}
                        onPdfsSelected={onPdfsSelected}
                        selectedPdfs={event.selectedPdfs}
                    />
                </ContentContainer>

                <HorizontalDivider />

                <BottomContainer>
                    <PrimaryContainedButton
                        sx={{
                            width: '30%',
                        }}
                        title={properties.continueButtonTitle}
                        onClick={() => properties.onSaveButtonClicked(groupSelectorRef.current!)}
                        disabled={!properties.canContinue}
                        isLoading={properties.isLoading}
                    />
                </BottomContainer>
            </Popup>
        </>
    )
}

const ContentContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    overflow: 'auto',

    '& svg': {
        fill: theme.palette.primary.main,
    },
}))

const BottomContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(3),
}))
