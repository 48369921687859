import { Box, Link as MuiLink, Typography, styled } from '@mui/material'
import { HorizontalDivider as Divider } from 'shared/lib/components/Divider'
import { PdfView } from 'shared/lib/components/PdfView'
import { useTranslation } from 'shared/lib/i18n'
import { Pdf } from 'shared/lib/models/Pdf'
import { grey_1 } from 'shared/lib/theme/Theme'
import { escapeMetaCharacters } from 'shared/lib/utils/StringUtils'
import { ReactComponent as ClockIcon } from '../../assets/svg/clock.svg'
import { ReactComponent as AttachmentIcon } from '../../assets/svg/attachment.svg'
import { ReactComponent as DescriptionIcon } from '../../assets/svg/description.svg'
import { ReactComponent as AttendanceIcon } from 'shared/lib/assets/svg/group_outlined.svg'
import { ReactComponent as LocationIcon } from '../../assets/svg/location_outlined.svg'
import { useDetailView } from '../../common/detailView/hooks'
import { MessageView } from '../../components/message/MessageView'
import { fileRepository, sessionRepository } from '../../index'
import { Event } from '../Event'
import {
    ContentSectionContainer,
    HeaderContainer,
    StartDateSingleDay,
    StartEndDateMultiDay,
    TimeTextView,
} from './components'
import { DateTime } from 'luxon'
import { EventAttendeesView } from '../components/EventAttendeesView'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'

interface Properties {
    isLoadingAttendances: boolean
    event: Event
    start: DateTime
    attendances: EventAttendance[]
}

export const EventContentView = ({ event, start, attendances, ...properties }: Properties) => {
    const translations = useTranslation()
    const { openGroupDetailView, openAttendancesView } = useDetailView()

    const description = escapeMetaCharacters(event.description)

    const signedInUser = sessionRepository.signedInUser
    const attendees = attendances.filter(
        (attendance) => attendance.state === EventAttendanceState.ATTENDING
    )
    const signedInUserAttendance = attendees.find(
        (attendance) => attendance.userId === signedInUser?.id
    )
    const attendeesWithoutSignedInUser = attendees.filter(
        (attendance) => attendance.userId !== signedInUser?.id
    )

    return (
        <>
            <HeaderContainer>
                <SubHeader>
                    {translations('calendar_of')}&nbsp;
                    {event.group.isHome ? (
                        event.group.name
                    ) : (
                        <Link onClick={() => openGroupDetailView(event.groupId)}>
                            {event.group.name}
                        </Link>
                    )}
                </SubHeader>
                <Header variant="h3">{event.title}</Header>
            </HeaderContainer>

            <HorizontalDivider />

            <ContentSectionContainer>
                <ClockIcon />
                <Box display="flex" flexDirection="column" alignItems="start">
                    <StartEndDateMultiDay
                        date={start}
                        isSameDay={event.isSameDay}
                        isAllDay={event.isAllDay}
                        label={translations('from')}
                    />
                    <StartDateSingleDay isSameDay={event.isSameDay} start={start} />
                    <StartEndDateMultiDay
                        date={event.end}
                        isSameDay={event.isSameDay}
                        isAllDay={event.isAllDay}
                        label={translations('to')}
                    />
                    <TimeTextView event={event} start={start} />
                </Box>
            </ContentSectionContainer>

            {event.description ? (
                <>
                    <HorizontalDivider />
                    <ContentSectionContainer>
                        <DescriptionIcon />
                        <MessageView
                            details={{
                                id: event.id,
                                hasMarkDown: true,
                                getMessage: () => description,
                            }}
                            linesToShowWhenCollapsed={16}
                        />
                    </ContentSectionContainer>
                </>
            ) : null}

            {event.canMembersRegisterAttendance && (
                <>
                    <HorizontalDivider />
                    <AttendeesAndPdfSectionContainer>
                        <AttendanceIcon />
                        <Box>
                            <Typography variant="body1" mb={1.5}>
                                {translations('attendees')}
                            </Typography>
                            <EventAttendeesView
                                isLoading={properties.isLoadingAttendances}
                                signedInUserImage={signedInUser?.image}
                                signedInUserState={signedInUserAttendance?.state}
                                attendeesWithoutSignedInUser={attendeesWithoutSignedInUser}
                                numberOfAttendees={attendees.length}
                                onButtonClicked={() => openAttendancesView(event.id, start)}
                            />
                        </Box>
                    </AttendeesAndPdfSectionContainer>
                </>
            )}

            {event.location ? (
                <>
                    <HorizontalDivider />
                    <LocationSectionContainer>
                        <LocationIcon />
                        <Link
                            href={`https://www.google.com/maps/search/?api=1&query=${event.location}`}
                            target="_blank"
                        >
                            {event.location}
                        </Link>
                    </LocationSectionContainer>
                </>
            ) : null}

            {event.pdfs.length ? (
                <>
                    <HorizontalDivider />
                    <AttendeesAndPdfSectionContainer>
                        <AttachmentIcon />
                        <Box overflow="hidden">
                            <Typography variant="body1">{translations('attachments')}</Typography>
                            <PdfsContainer>
                                {event.pdfs.map((pdf: Pdf) => (
                                    <PdfView
                                        key={pdf.key}
                                        pdf={pdf}
                                        translations={translations}
                                        fileRepository={fileRepository}
                                    />
                                ))}
                            </PdfsContainer>
                        </Box>
                    </AttendeesAndPdfSectionContainer>
                </>
            ) : null}
        </>
    )
}

const LocationSectionContainer = styled(ContentSectionContainer)(({ theme }) => ({
    p: {
        color: theme.palette.primary.main,
    },

    '& > svg': {
        fill: theme.palette.primary.main,
    },
}))

const AttendeesAndPdfSectionContainer = styled(ContentSectionContainer)(() => ({
    alignItems: 'start',
}))

const HorizontalDivider = () => <Divider width="100%" />

const Header = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(0.5),
    textAlign: 'left',
    wordBreak: 'break-all',
}))

const SubHeader = styled(Typography)(() => ({
    color: grey_1,
}))

const Link = styled(MuiLink)(() => ({
    cursor: 'pointer',
}))

const PdfsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
}))
