import { Box, BoxProps, styled, SvgIconProps } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ReactComponent as FileIcon } from 'shared/lib/assets/svg/file.svg'
import { ReactComponent as MediaIcon } from 'shared/lib/assets/svg/media.svg'
import { AddAttachmentButton } from 'shared/lib/components/buttons/AddAttachmentButton'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { CompressBox } from 'shared/lib/components/CompressBox'
import { LinkPreviewView } from 'shared/lib/components/LinkPreviewView'
import { MediaPreview } from 'shared/lib/components/media/MediaPreview'
import { PdfView } from 'shared/lib/components/PdfView'
import { Popup } from 'shared/lib/components/Popup'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { LocalVideo } from 'shared/lib/forms/LocalVideo'
import { ImageHelper } from 'shared/lib/images/ImageHelper'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { MediaItem, MediaItemType } from 'shared/lib/models/MediaItem'
import { Pdf } from 'shared/lib/models/Pdf'
import { grey_2, grey_3 } from 'shared/lib/theme/Theme'
import { useDetailView } from '../../common/detailView/hooks'
import { Group } from '../../groups/Group'
import { GroupsContext } from '../../groups/GroupsContextProvider'
import { GroupSelector } from '../../groups/groupselector/GroupSelector'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, groupRepository, linkPreviewHandler } from '../../index'
import { PostSharedGroupView } from '../../mychurch/components/PostSharedGroupView'
import { PostSharedPostView } from '../../mychurch/components/PostSharedPostView'
import {
    FullScreenMediaContext,
    FullScreenMediaContextProvider,
} from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { Post } from '../Post'
import { SharedGroup } from '../SharedGroup'
import { Optional } from '../../utils/Misc'
import { useUsers } from '../../user/hooks'
import ChurchContext from '../../churches/ChurchContextProvider'
import { isShareUserUrl } from 'shared/lib/utils/StringUtils'
import {
    MarkdownInputWithTaggingSupport,
    MarkdownInputWithTaggingSupportRefMethods,
} from './input/MarkdownInputWithTaggingSupport'
import { AtIcon as SharedAtIcon } from 'shared/lib/assets/svg/AtIcon'
import { PostSharedEventView } from '../../mychurch/components/PostSharedEventView.tsx'
import { SharedEvent } from '../SharedEvent'

interface Properties {
    isLoading: boolean
    isGroupSelectorEnabled: boolean
    isSizeToBig: boolean
    canContinue: boolean
    hasDeletedLinkPreview: boolean
    withBackdrop?: boolean
    selectedGroupId?: string
    localFiles?: LocalFile[]
    message: string
    continueButtonText: string
    sharedPostId?: string
    sharedGroupId?: string
    sizeFromContainingItems: number
    linkPreview: LinkPreview | undefined
    pdfFile: LocalPdf | Pdf | undefined
    mediaItems: MediaItem[]
    sharedPost?: Post
    sharedGroup?: Group | SharedGroup
    sharedEvent?: SharedEvent

    onMessageChanged(text: string): void
    onLinkPreviewUpdated(linkPreview?: LinkPreview): void
    onDeleteButtonLinkPreviewClicked(): void
    onMediaItemsSelected(images: LocalImage[], videos: LocalVideo[]): void
    onDeleteImageOrVideoClicked(mediaItem: MediaItem): void
    onPdfSelected(files: LocalPdf[]): void
    onDeleteButtonPdfClicked(): void
    onDeleteSharedPostClicked(): void
    onDeleteSharedGroupClicked(): void
    onDeleteSharedEventClicked(): void
    onCloseButtonClicked(): void
    onContinueButtonClicked(group: Group): void
}

const Component = (properties: Properties) => {
    const translations = useTranslation()
    const groupSelectorRef = useRef<HTMLDivElement>(null)
    const messageInputRef = useRef<MarkdownInputWithTaggingSupportRefMethods>(null)

    const { isDetailViewDisplayedAsPopup } = useDetailView()
    const { church } = useContext(ChurchContext)!
    const { groups } = useContext(GroupsContext)
    const { openFullscreenMedia } = useContext(FullScreenMediaContext)!
    const [group, setGroup] = useState<Group | undefined>()
    const [compressedProgress, setCompressedProgress] = useState<number>(0)
    const [isLinkPreviewLoading, setIsLinkPreviewLoading] = useState<boolean>(false)
    const [failedLinkPreviewUrl, setFailedLinkPreviewUrl] = useState<string | undefined>()
    const [groupMemberIdsInGroup, setGroupMemberIdsInGroup] = useState<string[]>([])

    const { users } = useUsers({
        isApproved: true,
    })
    const { isLoading, hasDeletedLinkPreview, selectedGroupId } = properties
    const { localFiles, mediaItems, pdfFile, linkPreview, sharedPost, sharedGroup, sharedEvent } =
        properties

    const withBackdrop = properties.withBackdrop ?? true

    const canCreatePostGroups = groups.filter((group) => {
        return group.canCreatePosts && !group.isMyChurch && group.id !== sharedGroup?.id
    })

    const isUsingLinkPreview =
        linkPreview !== undefined || isLinkPreviewLoading || failedLinkPreviewUrl !== undefined

    const canAddImagesAndVideos =
        pdfFile === undefined &&
        !isUsingLinkPreview &&
        sharedPost === undefined &&
        sharedGroup === undefined &&
        sharedEvent === undefined &&
        !isLoading

    const canAddPdf =
        mediaItems.length === 0 &&
        !isUsingLinkPreview &&
        sharedPost === undefined &&
        sharedGroup === undefined &&
        sharedEvent === undefined &&
        !isLoading

    const generateLinkPreview = async (url: string) => {
        setIsLinkPreviewLoading(true)
        const { linkPreview, error } = await linkPreviewHandler.generateLinkPreview(url)

        setIsLinkPreviewLoading(false)
        setFailedLinkPreviewUrl(Boolean(error) ? url : undefined)
        properties.onLinkPreviewUpdated(linkPreview)
    }

    const onLocalImagesAndVideosSelected = async (inputtedFiles: LocalFile[]) => {
        setCompressedProgress(1)

        const compressedImages: LocalImage[] = []
        const compressedVideos: LocalVideo[] = []

        for (let index = 0; index < inputtedFiles.length; index++) {
            const localFile = inputtedFiles[index]

            if (localFile instanceof LocalImage) {
                const localImage = await ImageHelper.compressImage({
                    blob: localFile.file,
                    fileName: localFile.name,
                })

                compressedImages.push(localImage)
            } else if (localFile instanceof LocalVideo) {
                compressedVideos.push(localFile)
            }

            setCompressedProgress(Math.round(((index + 1) / inputtedFiles.length) * 100))
        }

        setCompressedProgress(100)

        properties.onMediaItemsSelected(compressedImages, compressedVideos)
    }

    const onContinueClicked = () => {
        if (!group) {
            groupSelectorRef.current?.click()
            return
        }

        properties.onContinueButtonClicked(group)
    }

    const getFirstValidUrlThatIsNotAUserTagFromMessage = (): Optional<string> => {
        const urls = [
            ...(properties.message
                .replaceAll('\\', '')
                .matchAll(new RegExp(/\[.+\]\((.+)\)/, 'gi')) ?? []),
        ].map(([_, url]) => url)

        const urlsWithoutUserTags = urls.filter((url) => !isShareUserUrl(url))

        return urlsWithoutUserTags.pop()
    }

    useEffect(() => {
        if (!group?.id) {
            return
        }

        groupRepository.getGroupMemberIds(group.id).then(setGroupMemberIdsInGroup)
    }, [group?.id])

    useEffect(() => {
        const url = getFirstValidUrlThatIsNotAUserTagFromMessage()
        const canGenerateLinkPreview =
            (canAddImagesAndVideos && canAddPdf && !hasDeletedLinkPreview) ||
            Boolean(linkPreview) ||
            Boolean(failedLinkPreviewUrl)

        if (
            (url && linkPreview?.url?.includes(url) === true) ||
            (failedLinkPreviewUrl && failedLinkPreviewUrl === url)
        ) {
            return
        }

        if (url === undefined) {
            setFailedLinkPreviewUrl(undefined)
            properties.onLinkPreviewUpdated(undefined)
        } else if (canGenerateLinkPreview) {
            generateLinkPreview(url)
        }
    }, [properties.message]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setGroup(groups.find((group) => group.id === selectedGroupId))
    }, [selectedGroupId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!localFiles) {
            return
        }
        const localPdfs = LocalFile.filterOnType(localFiles, LocalPdf)
        properties.onPdfSelected(localPdfs)

        const localImages = LocalFile.filterOnType(localFiles, LocalImage)
        const localVideos = LocalFile.filterOnType(localFiles, LocalVideo)
        onLocalImagesAndVideosSelected([...localImages, ...localVideos])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Popup isVisible={true} withBackdrop={!isDetailViewDisplayedAsPopup && withBackdrop}>
            <TitleContainer>
                <GroupSelector
                    groups={canCreatePostGroups}
                    ref={groupSelectorRef}
                    selectedGroup={group}
                    onGroupSelected={setGroup}
                    isEnabled={properties.isGroupSelectorEnabled}
                />
                <ButtonContainer>
                    <FilledCloseButtonWithWhiteCross
                        disabled={isLoading}
                        buttonSize="small"
                        onClick={properties.onCloseButtonClicked}
                    />
                </ButtonContainer>
            </TitleContainer>
            <PostContentContainer id="PostContentContainer">
                <MarkdownInputWithTaggingSupport
                    ref={messageInputRef}
                    value={properties.message}
                    onChanged={properties.onMessageChanged}
                    applicationId={church!.applicationId}
                    apiUrl={church!.apiUrl!}
                    taggableUsers={users.map((user) => ({
                        ...user,
                        isGroupMember: !group?.id || groupMemberIdsInGroup.includes(user.id),
                    }))}
                    placeholder={translations('enter_post_hint')}
                    readonly={isLoading}
                    onClose={properties.onCloseButtonClicked}
                    notificationContainerAnchorId="PostContentContainer"
                    postAttachments={
                        <AutoSizer disableHeight>
                            {({ width }) => (
                                <Box width={width} pb={2}>
                                    {(linkPreview || failedLinkPreviewUrl) && (
                                        <LinkPreviewView
                                            hasWhiteBackground={false}
                                            hasFailedLoadingLinkPreview={Boolean(
                                                failedLinkPreviewUrl
                                            )}
                                            linkPreview={linkPreview}
                                            translations={{
                                                loading_link_preview_failed: translations(
                                                    'loading_link_preview_failed'
                                                ),
                                            }}
                                            onDeleteButtonClicked={() => {
                                                setFailedLinkPreviewUrl(undefined)
                                                properties.onDeleteButtonLinkPreviewClicked()
                                            }}
                                        />
                                    )}
                                    {pdfFile instanceof LocalPdf && (
                                        <PdfView
                                            hasExceeded={properties.isSizeToBig}
                                            uploadedPdf={pdfFile}
                                            fileRepository={fileRepository}
                                            translations={translations}
                                            onDeleteButtonClicked={
                                                properties.onDeleteButtonPdfClicked
                                            }
                                            disableDeleteButton={isLoading}
                                        />
                                    )}
                                    {pdfFile instanceof Pdf && (
                                        <PdfView
                                            hasExceeded={properties.isSizeToBig}
                                            pdf={pdfFile}
                                            fileRepository={fileRepository}
                                            translations={translations}
                                            onDeleteButtonClicked={
                                                properties.onDeleteButtonPdfClicked
                                            }
                                            disableDeleteButton={isLoading}
                                        />
                                    )}

                                    {compressedProgress > 0 && compressedProgress < 100 && (
                                        <Box mb={2}>
                                            <CompressBox
                                                compressedProgress={compressedProgress}
                                                translations={translations}
                                            />
                                        </Box>
                                    )}

                                    <PostSharedPostView
                                        width={width}
                                        parentMargins={0}
                                        sharedPost={sharedPost}
                                        sharedPostId={properties.sharedPostId}
                                        disableDeleteButton={isLoading}
                                        isEditing={true}
                                        onDeleteButtonClicked={properties.onDeleteSharedPostClicked}
                                    />

                                    <PostSharedGroupView
                                        isEditing={true}
                                        isDeleteButtonEnabled={!isLoading}
                                        hasWhiteBackground={false}
                                        sharedGroup={sharedGroup}
                                        sharedGroupId={properties.sharedGroupId}
                                        onDeleteButtonClicked={
                                            properties.onDeleteSharedGroupClicked
                                        }
                                    />

                                    <PostSharedEventView
                                        isEditing={true}
                                        isReadOnly={true}
                                        isDeleteButtonEnabled={!isLoading}
                                        hasWhiteBackground={false}
                                        sharedEvent={sharedEvent}
                                        onDeleteButtonClicked={
                                            properties.onDeleteSharedEventClicked
                                        }
                                    />

                                    <MediaPreview
                                        width={width}
                                        mediaItems={mediaItems}
                                        isDeleteButtonEnabled={!isLoading}
                                        onMediaItemSelected={openFullscreenMedia}
                                        onDeleteButtonClicked={
                                            properties.onDeleteImageOrVideoClicked
                                        }
                                    />
                                </Box>
                            )}
                        </AutoSizer>
                    }
                />
            </PostContentContainer>

            <FooterContainer>
                <AttachmentButtonsContainer>
                    <AddAttachmentButton
                        acceptedFiles={[MediaItemType.IMAGE, MediaItemType.VIDEO]}
                        onFilesSelected={onLocalImagesAndVideosSelected}
                        translations={translations}
                        isEnabled={canAddImagesAndVideos}
                        button={
                            <IconButton isEnabled={canAddImagesAndVideos}>
                                <Media isEnabled={canAddImagesAndVideos} />
                            </IconButton>
                        }
                        showErrorPopup={true}
                        verticalPositionPopup={'center'}
                        horizontalPositionPopup={'center'}
                        sizeFromContainingItems={properties.sizeFromContainingItems}
                    />
                    <AddAttachmentButton
                        canSelectMultipleFiles={false}
                        acceptedFiles={[MediaItemType.PDF]}
                        onFilesSelected={(localFiles: LocalFile[]) =>
                            properties.onPdfSelected(LocalFile.filterOnType(localFiles, LocalPdf))
                        }
                        translations={translations}
                        button={
                            <IconButton isEnabled={canAddPdf}>
                                <PdfIcon isEnabled={canAddPdf} />
                            </IconButton>
                        }
                        isEnabled={canAddPdf}
                    />
                    <IconButton
                        isEnabled={!properties.isLoading}
                        onClick={() => messageInputRef.current?.startTagging()}
                    >
                        <AtIcon isEnabled={!properties.isLoading} />
                    </IconButton>
                </AttachmentButtonsContainer>

                <PrimaryContainedButton
                    disabled={!properties.canContinue}
                    onClick={onContinueClicked}
                    isLoading={isLoading}
                >
                    {properties.continueButtonText}
                </PrimaryContainedButton>
            </FooterContainer>
        </Popup>
    )
}

export const PostPopup = (properties: Properties) => (
    <FullScreenMediaContextProvider>
        <Component {...properties} />
    </FullScreenMediaContextProvider>
)

const TitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(3),
    borderBottom: `2px solid ${theme.palette.background.default}`,
}))

const ButtonContainer = styled(Box)(() => ({
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
}))

const PostContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flex: 1,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: theme.spacing(0, 2),
    width: '100%',
    justifyContent: 'space-between',
    userSelect: 'none',
}))

const FooterContainer = styled(Box)(({ theme }) => ({
    borderTop: `2px solid ${grey_3}`,
    width: '100%',
    bottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
}))

const AttachmentButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
}))

const IconButton = styled(
    ({ isEnabled, onClick, ...properties }: BoxProps & { isEnabled: boolean }) => (
        <Box {...properties} onClick={isEnabled ? onClick : undefined} />
    )
)(({ isEnabled }) => ({
    display: 'flex',
    height: '49px',
    width: '49px',
    borderRadius: '16px',
    backgroundColor: grey_3,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    cursor: isEnabled ? 'pointer' : 'auto',
}))

const Media = styled(({ isEnabled, ...properties }: SvgIconProps & { isEnabled: boolean }) => (
    <MediaIcon {...properties} />
))(({ theme, isEnabled }) => ({
    fill: isEnabled ? theme.palette.primary.main : grey_2,
}))

const PdfIcon = styled(({ isEnabled, ...properties }: SvgIconProps & { isEnabled: boolean }) => (
    <FileIcon {...properties} />
))(({ theme, isEnabled }) => ({
    fill: isEnabled ? theme.palette.primary.main : grey_2,
}))

const AtIcon = styled(({ isEnabled, ...properties }: SvgIconProps & { isEnabled: boolean }) => (
    <SharedAtIcon {...properties} />
))(({ theme, isEnabled }) => ({
    fill: isEnabled ? theme.palette.primary.main : grey_2,
}))
