import { Box, BoxProps, styled, Typography } from '@mui/material'
import { Avatar as BaseAvatar, AvatarProperties } from 'shared/lib/components/Avatar'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { grey_1, grey_2, grey_3 } from 'shared/lib/theme/Theme'
import { AvatarSkeleton } from '../common/detailView'
import { useDetailView } from '../common/detailView/hooks'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository } from '../index'
import { MessageView } from '../components/message/MessageView'
import { createdAtString } from '../utils/DateTimeUtils'
import { Comment } from './Comment'
import { CommentActionsButton } from './CommentActionsButton'
import { getFullName } from 'shared/lib/utils/StringUtils'

export interface CommentRowViewModel {
    comment: Comment
    isHighlighted?: boolean
}

interface Properties {
    viewModel: CommentRowViewModel
    paddingBottom: string

    onEditButtonClicked: (comment: Comment) => void
    onDeleteButtonClicked: (commentId: string) => void
}

export const CommentRow = ({
    viewModel: { comment, isHighlighted },
    paddingBottom,
    onEditButtonClicked,
    onDeleteButtonClicked,
}: Properties) => {
    const translations = useTranslation()
    const { openUserDetailView } = useDetailView()

    const creator = comment.creator

    const onClickUserDetail = creator ? () => openUserDetailView(creator!.id) : undefined

    return (
        <Container paddingBottom={paddingBottom}>
            <Avatar
                src={fileRepository.getThumbnailUrl(creator?.image)}
                onClick={onClickUserDetail}
            />
            <ContentContainer isHighlighted={isHighlighted}>
                <Header>
                    <Name onClick={onClickUserDetail} noWrap={true}>
                        {creator ? getFullName(creator) : translations('deleted_user')}
                    </Name>
                    <PostDateContainer>
                        <PostDateAndEdited>
                            {createdAtString(comment.createdAt, translations)}
                        </PostDateAndEdited>
                        {comment.isEdited && (
                            <PostDateAndEdited>&#x2022; {translations('edited')}</PostDateAndEdited>
                        )}
                    </PostDateContainer>
                    {comment.canEdit && (
                        <CommentActionsButton
                            onEditButtonClicked={() => onEditButtonClicked(comment)}
                            onDeleteButtonClicked={() => onDeleteButtonClicked(comment.id)}
                        />
                    )}
                </Header>
                <Box pr={2} width={'100%'}>
                    <MessageView
                        details={comment}
                        backgroundColor={isHighlighted ? grey_2 : grey_3}
                    />
                </Box>
            </ContentContainer>
        </Container>
    )
}

export const CommentRowLoading = ({ paddingBottom }: { paddingBottom: string }) => {
    return (
        <Container paddingBottom={paddingBottom}>
            <AvatarSkeleton
                width={40}
                height={40}
                sx={{
                    minWidth: 40,
                    marginTop: 0.5,
                }}
            />
            <Skeleton
                variant="rounded"
                width="100%"
                height="93px"
                sx={{
                    marginLeft: 2,
                }}
            />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    gap: theme.spacing(1),
}))

const ContentContainer = styled(
    ({ isHighlighted, ...props }: BoxProps & { isHighlighted?: boolean }) => <Box {...props} />
)(({ theme, isHighlighted }) => ({
    backgroundColor: isHighlighted ? grey_2 : grey_3,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',
    flexGrow: 1,
    overflow: 'hidden',
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
}))

const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '40px',
    alignItems: 'center',
}))

const Avatar = styled((props: Omit<AvatarProperties, 'width' | 'height'>) => (
    <BaseAvatar {...props} width={40} height={40} />
))(({ theme, onClick }) => ({
    marginTop: theme.spacing(0.5),
    cursor: onClick ? 'pointer' : 'unset',
}))

const Name = styled(Typography)(({ onClick }) => ({
    fontSize: 14,
    fontWeight: 700,
    cursor: onClick ? 'pointer' : 'unset',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: '50px',
}))

const PostDateContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    gap: theme.spacing(0.5),
    height: '100%',
    alignItems: 'center',
    overflow: 'hidden',
}))

const PostDateAndEdited = styled(Typography)(() => ({
    color: grey_1,
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}))
