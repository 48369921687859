import { Comment } from '../comments/Comment'
import { CommentRowViewModel } from '../comments/CommentRow'

export interface PostDetailViewModel {}

export class PostDetailMessageViewModel implements PostDetailViewModel {}
export class PostDetailMediaViewModel implements PostDetailViewModel {}
export class PostDetailPdfViewModel implements PostDetailViewModel {}
export class PostDetailLinkPreviewViewModel implements PostDetailViewModel {}
export class PostDetailCharitiesViewModel implements PostDetailViewModel {}
export class PostDetailFundraiserViewModel implements PostDetailViewModel {}
export class PostDetailDiscoverGroupsViewModel implements PostDetailViewModel {}
export class PostDetailSharedGroupViewModel implements PostDetailViewModel {}
export class PostDetailSharedPostViewModel implements PostDetailViewModel {}
export class PostDetailSharedEventViewModel implements PostDetailViewModel {}
export class PostDetailLikesAndShareViewModel implements PostDetailViewModel {}
export class PostDetailDividerViewModel implements PostDetailViewModel {}
export class PostDetailCommentViewModel implements PostDetailViewModel, CommentRowViewModel {
    constructor(
        public readonly comment: Comment,
        public readonly isHighlighted: boolean = false
    ) {}
}
