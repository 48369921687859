import { Image } from 'shared/lib/models/Image'
import { AppType } from './AppType'
import { TranslationFn } from 'shared/lib/WithTranslations'
import { isAndroid, isWindows } from 'mobile-device-detect'

export class Church {
    get canCreateGroups() {
        return this.applicationId !== 'app.donkeymobile.bisdomrotterdam'
    }

    get appOrPlayStoreUrl(): string {
        if (this.appleAppTrackId === undefined || isAndroid || isWindows) {
            return `https://play.google.com/store/apps/details?id=${this.applicationId}`
        } else {
            return `https://apps.apple.com/app/id${this.appleAppTrackId}`
        }
    }

    constructor(
        readonly applicationId: string,
        readonly appType: AppType,
        readonly developerName: string,
        readonly name: string,
        readonly color: string,
        readonly image: Image,
        readonly appleAppTrackId: string | undefined,
        readonly apiUrl: string
    ) {}

    getUserListPageNameTranslationKey() {
        switch (true) {
            case this.applicationId === 'app.donkeymobile.olivafamily':
                return 'page_church_member_list_familiy'
            case this.appType === AppType.CATHOLIC:
                return 'page_church_member_list_catholic'
            case this.appType === AppType.ORGANIZATION:
            case this.applicationId === 'app.donkeymobile.vvkampen':
                return 'page_church_member_list_organization'
            case this.appType === AppType.JEWISH:
                return 'page_church_member_list_jewish'
            default:
                return 'page_church_member_list'
        }
    }

    getMyChurchGroupName(translations: TranslationFn) {
        switch (this.applicationId) {
            case 'app.donkeymobile.izb':
                return translations('my_church_group_name_izb')
            case 'app.donkeymobile.interkerkelijkdovenpastoraat':
                return translations('my_church_group_name_idp')
            case 'app.donkeymobile.bisdomrotterdam':
                return translations('my_church_group_name_bisdom')
            case 'app.donkeymobile.legerdesheilsmiddennederland':
                return translations('my_church_group_my_timeline')
            case 'app.donkeymobile.legerdesheilsnoordwest':
                return translations('my_church_group_name_my_region')
            case 'app.donkeymobile.vkb':
                return translations('my_church_group_name_vkb')
            case 'app.donkeymobile.pknpioniersplekzinopschool':
                return translations('my_church_group_name_schoolplein')
            case 'app.donkeymobile.stichtingkingsmen':
                return translations('groups')
            case 'app.donkeymobile.stichtingiam':
            case 'app.donkeymobile.ldhjeugdbeschermingreclassering':
            case 'app.donkeymobile.utrechtdiaconie':
            case 'app.donkeymobile.camazending':
                return translations('my_church_group_my_timeline')
            case 'app.donkeymobile.reimertgroep':
                return translations('my_church_group_name_reimert')
            case 'app.donkeymobile.dewittenberg':
                return translations('my_church_group_name_wittenberg')
            case 'app.donkeymobile.jesusrevivalgo':
                return translations('my_church_group_name_jesusrevivalgo')
            case 'app.donkeymobile.dezaligezalm':
                return translations('my_church_group_name_my_plaza')
            case 'app.donkeymobile.unieabc':
                return translations('my_church_group_name_unieabc')
            case 'app.donkeymobile.olivafamily':
                return translations('my_family_my_church')
        }

        switch (this.appType) {
            case AppType.CATHOLIC:
                return translations('my_church_group_name_catholic')
            case AppType.ORGANIZATION:
                return translations('my_church_group_my_timeline')
            case AppType.JEWISH:
                return translations('my_church_group_name_jewish')
            default:
                return translations('page_my_church')
        }
    }

    wordUsedForChurch(translations: TranslationFn): string {
        switch (this.appType) {
            case AppType.CHURCH:
                return translations('church_protestant')
            case AppType.CATHOLIC:
                return translations('church_catholic')
            case AppType.ORGANIZATION:
                return translations('church_organisation')
            case AppType.JEWISH:
                return translations('church_jewish')
        }
    }

    static fromResponse(response: any): Church {
        return new Church(
            response.applicationId,
            response.appType,
            response.developerName,
            response.name,
            response.color,
            response.image,
            response.appleAppTrackId,
            response.apiUrl
        )
    }
}
