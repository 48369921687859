import { DateTime } from 'luxon'
import { useCallback, useState } from 'react'
import { MAX_PDF_SIZE_BYTES } from 'shared/lib/common/Constants'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'
import { EditOrDeleteRecurringEventOption } from 'shared/lib/recurring/EditOrDeleteRecurringEventOption'
import { DateTimeValidator } from 'shared/lib/utils/DateTimeValidator'
import { Event } from '../Event'
import { EventPopupEvent } from './EventPopup'

export const useCreateOrEditEventState = (
    initialState: {
        event?: Event
        selectedPdfs?: LocalPdf[]
    } = {}
) => {
    const event = initialState.event
    const [state, setState] = useState<EventPopupEvent>({
        title: event?.title || '',
        description: event?.description || '',
        location: event?.location || '',
        start: event?.isRecurring
            ? event?.start.minus({ days: event.numberOfDaysFromInitialStart })
            : event?.initialStart || DateTime.local().startOf('hour'),
        end: event?.end || DateTime.local().startOf('hour').plus({ hours: 1 }),
        isAllDay: event?.isAllDay ?? false,
        recurrenceRule: event?.start
            ? RecurrenceRuleFactory.fromRRuleString(event.start, event?.recurrenceRule)
            : undefined,
        canMembersRegisterAttendance: event?.canMembersRegisterAttendance ?? true,
        pdfs: event?.pdfs || [],
        selectedPdfs: initialState.selectedPdfs || [],
    })

    const onFieldChanged = useCallback(
        <T extends keyof EventPopupEvent>(field: T) =>
            (value: EventPopupEvent[T]) => {
                setState((prevState) => ({ ...prevState, [field]: value }))
            },
        []
    )

    const hasChangedRecurrenceRule =
        JSON.stringify(event?.rrule) !== JSON.stringify(state.recurrenceRule)

    const hasChanges =
        state.title !== event?.title ||
        state.description !== event?.description ||
        state.location !== event?.location ||
        state.start.toISO() !==
            (event?.isRecurring
                ? event?.start.minus({ days: event.numberOfDaysFromInitialStart })
                : event?.initialStart
            ).toISO() ||
        state.end.toISO() !== event?.end?.toISO() ||
        state.isAllDay !== event?.isAllDay ||
        hasChangedRecurrenceRule ||
        state.canMembersRegisterAttendance !== event?.canMembersRegisterAttendance ||
        state.pdfs !== event?.pdfs ||
        state.selectedPdfs.length > 0

    const canSaveChanges =
        state.title.trim().length > 0 &&
        state.selectedPdfs.every((pdf) => pdf.file.size < MAX_PDF_SIZE_BYTES) &&
        state.start !== undefined &&
        DateTimeValidator.isValidPeriod(state.start, state.end, state.isAllDay)

    const getEditOptions = (): EditOrDeleteRecurringEventOption[] | undefined => {
        if (event === undefined || event.rrule === undefined || state.recurrenceRule === undefined)
            return undefined

        let hasOnlyThisEventOption = !hasChangedRecurrenceRule
        let hasThisAndFollwingEventsOption = !event.isFirstRecurrence
        let hasAllEventsOption =
            (!event.hasDurationOfMultipleDays ||
                (event.hasDurationOfMultipleDays && event.isFirstRecurrence)) &&
            event.start.minus({ days: event.numberOfDaysFromInitialStart }).equals(state.start)

        const options: EditOrDeleteRecurringEventOption[] = []
        if (hasOnlyThisEventOption) options.push(EditOrDeleteRecurringEventOption.THIS_EVENT)
        if (hasThisAndFollwingEventsOption)
            options.push(EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS)
        if (hasAllEventsOption) options.push(EditOrDeleteRecurringEventOption.ALL_EVENTS)

        if (options.length === 0) {
            options.push(EditOrDeleteRecurringEventOption.ALL_EVENTS)
        }

        return options
    }

    return {
        event: state,
        hasChanges,
        canSaveChanges,
        onFieldChanged,
        getEditOptions,
    }
}
