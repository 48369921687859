import { Box, BoxProps, styled, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { useTranslation } from 'shared/lib/i18n'
import { grey_3 } from 'shared/lib/theme/constants'
import { useDetailView } from '../../common/detailView/hooks'
import { EventAttendanceView } from '../../events/components/EventAttendanceView'
import { EventAttendeesView } from '../../events/components/EventAttendeesView'
import { SharedEvent } from '../../posts/SharedEvent'
import { PostListItemContainer } from '../PostListComponents'
import { FilledCloseButtonWithWhiteCross } from 'shared/lib/components/buttons/CloseButton'

interface Properties {
    isClickable?: boolean
    isEditing?: boolean
    isReadOnly?: boolean
    isDeleteButtonEnabled?: boolean
    hasWhiteBackground?: boolean
    sharedEvent?: SharedEvent
    onDeleteButtonClicked?: () => void
    onSharedEventClicked?: (sharedEvent: SharedEvent) => void
    onEventAttendButtonClicked?: (state: EventAttendanceState) => void
}

export const PostSharedEventView = ({ sharedEvent, ...properties }: Properties) => {
    const translations = useTranslation()

    const { openAttendancesView } = useDetailView()

    const isClickable = properties.isClickable ?? true
    const isEditing = properties.isEditing ?? false
    const isReadOnly = properties.isReadOnly ?? false
    const hasWhiteBackground = properties.hasWhiteBackground ?? false

    const getDeleteButtonIfNeeded = () => {
        return (
            properties.onDeleteButtonClicked && (
                <FilledCloseButtonWithWhiteCross
                    disabled={!properties.isDeleteButtonEnabled}
                    onClick={() => properties.onDeleteButtonClicked!()}
                />
            )
        )
    }

    if (!sharedEvent) {
        return <></>
    }

    if (sharedEvent.isDeleted === true) {
        return (
            <PostListItemContainer isEditing={isEditing}>
                <DeletedEventContainer bgcolor={hasWhiteBackground ? 'white' : grey_3}>
                    <Typography variant="body1">
                        {translations('shared_event_has_been_deleted')}
                    </Typography>
                    {getDeleteButtonIfNeeded()}
                </DeletedEventContainer>
            </PostListItemContainer>
        )
    }

    const hasDurationOfMultipleDays = sharedEvent.hasDurationOfMultipleDays
    const start = sharedEvent.start
    const startString = start.toFormat('EEE d MMM yyyy')
    const end = sharedEvent.end!
    const endString = end.toFormat('EEE d MMM yyyy')
    const from = translations('from')
    const to = translations('to')
    const startTime = start.toLocaleString(DateTime.TIME_24_SIMPLE)
    const endTime = end?.toLocaleString(DateTime.TIME_24_SIMPLE)
    const dateTimeFormat = '%s ∙ %s'
    const isAllDay = sharedEvent.isAllDay === true

    const isExpired = sharedEvent.isExpired === true
    const canMembersRegisterAttendance = sharedEvent.canMembersRegisterAttendance
    const mostRecentAttendees = sharedEvent.mostRecentAttendees ?? []

    const getSubtitle1 = () => {
        if (hasDurationOfMultipleDays && isAllDay) {
            return start.toLocaleString(DateTime.DATE_HUGE)
        } else if (hasDurationOfMultipleDays) {
            return dateTimeFormat.replace('%s', startString).replace('%s', startTime)
        } else if (isAllDay) {
            return translations('agenda_all_day')
        } else {
            return `<strong>${from}</strong> ${startTime} <strong>${to}</strong> ${endTime}`
        }
    }

    const getSubtitle2 = () => {
        if (isAllDay) {
            return end.toLocaleString(DateTime.DATE_HUGE)
        } else {
            return dateTimeFormat.replace('%s', endString).replace('%s', endTime)
        }
    }

    return (
        <PostListItemContainer isEditing={isEditing}>
            <Container
                isClickable={isClickable && !isReadOnly}
                bgcolor={hasWhiteBackground ? 'white' : grey_3}
                onClick={() => properties.onSharedEventClicked?.(sharedEvent)}
            >
                <Box display="flex" flexDirection="row" gap={1}>
                    <DateContainer hasWhiteBackground={hasWhiteBackground}>
                        <DateTextView variant="body1">{start.day}</DateTextView>
                        <MonthTextView variant="body1">{start.toFormat('MMM')}</MonthTextView>
                    </DateContainer>
                    <TitleAndTimeContainer hasWhiteBackground={hasWhiteBackground}>
                        <TitleTextView variant="body1" sx={{ marginRight: isEditing ? 6 : 3 }}>
                            {sharedEvent.title}
                        </TitleTextView>
                        <SubTitleTextView
                            variant="body2"
                            dangerouslySetInnerHTML={{ __html: getSubtitle1() }}
                        />
                        {hasDurationOfMultipleDays && (
                            <SubTitleTextView variant="body2">{getSubtitle2()}</SubTitleTextView>
                        )}
                    </TitleAndTimeContainer>
                </Box>

                {canMembersRegisterAttendance && mostRecentAttendees.length > 0 && (
                    <Box
                        borderRadius="16px"
                        bgcolor={hasWhiteBackground ? grey_3 : 'white'}
                        p="12px"
                    >
                        <EventAttendeesView
                            isReadOnly={isReadOnly}
                            hasWhiteBackground={hasWhiteBackground}
                            showAttendeesButtonFullWidth={true}
                            signedInUserState={sharedEvent.signedInUserAttendanceState}
                            signedInUserImage={sharedEvent.signedInUserImage}
                            attendeesWithoutSignedInUser={sharedEvent.mostRecentAttendees}
                            numberOfAttendees={sharedEvent.numberOfAttendees}
                            onButtonClicked={() =>
                                openAttendancesView(sharedEvent.id, sharedEvent.start)
                            }
                        />
                    </Box>
                )}

                {canMembersRegisterAttendance && !isExpired && (
                    <Box borderRadius="16px" bgcolor={hasWhiteBackground ? grey_3 : 'white'} px={2}>
                        <EventAttendanceView
                            isReadOnly={isReadOnly}
                            hasWhiteBackground={hasWhiteBackground}
                            state={sharedEvent.signedInUserAttendanceState}
                            onStateChanged={(state) =>
                                properties.onEventAttendButtonClicked?.(state)
                            }
                        />
                    </Box>
                )}

                {isExpired && (
                    <ExpiredTextView variant="body2">
                        {translations('event_expired_message')}
                    </ExpiredTextView>
                )}

                <DeleteButtonContainer>{getDeleteButtonIfNeeded()}</DeleteButtonContainer>
            </Container>
        </PostListItemContainer>
    )
}

const DeletedEventContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

const Container = styled(({ isClickable, ...properties }: BoxProps & { isClickable: boolean }) => (
    <Box {...properties} />
))(({ theme, isClickable }) => ({
    position: 'relative',
    cursor: isClickable ? 'pointer' : 'default',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    padding: '8px',
    gap: theme.spacing(1),
}))

const DateContainer = styled(
    ({ hasWhiteBackground, ...properties }: BoxProps & { hasWhiteBackground: boolean }) => (
        <Box {...properties} />
    )
)(({ hasWhiteBackground }) => ({
    minWidth: '80px',
    backgroundColor: hasWhiteBackground ? grey_3 : 'white',
    alignContent: 'center',
    borderRadius: '16px',
}))

const DateTextView = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
}))

const MonthTextView = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    textAlign: 'center',
    color: theme.palette.primary.main,
}))

const TitleAndTimeContainer = styled(
    ({ hasWhiteBackground, ...properties }: BoxProps & { hasWhiteBackground: boolean }) => (
        <Box {...properties} />
    )
)(({ hasWhiteBackground }) => ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: hasWhiteBackground ? grey_3 : 'white',
    borderRadius: '16px',
    paddingBottom: theme.spacing(3),
    overflow: 'hidden',
}))

const TitleTextView = styled(Typography)(({ theme }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    margin: theme.spacing(3, 3, 0),
    fontSize: '27px',
    lineHeight: 1.2,
    fontWeight: 'bold',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
}))

const SubTitleTextView = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(0, 3, 0),
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
}))

const ExpiredTextView = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1, 2, 1, 2),
    flexGrow: 1,
    textAlign: 'center',
}))

const DeleteButtonContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
}))
