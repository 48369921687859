import { useCallback } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Event } from '../../events/Event'
import { Group, MY_CHURCH_GROUP_ID } from '../../groups/Group'
import { groupRepository } from '../../index'
import { Post } from '../../posts/Post'
import { XOr } from '../../utils/Misc'
import { DateTime } from 'luxon'

export enum SupportedDetailView {
    APP_ACCESS_REQUESTS = 'appAccessRequests',
    COMMENTS = 'comments',
    EVENT = 'eventId',
    ATTENDANCES = 'attendances',
    FEED = 'feedId',
    GROUP = 'groupId',
    GROUP_ACCESS_REQUESTS = 'groupAccessRequests',
    GROUP_MEMBERS = 'groupMembers',
    LIKES = 'likes',
    POST = 'postId',
    USER = 'userId',
}

export enum SupportedDisplayType {
    POPUP = 'popup',
}

export const APPLICATION_SEARCH_KEY = 'applicationId'
export const DISPLAY_TYPE_SEARCH__KEY = 'displayType'
export const EVENT_START_KEY = 'eventStart'

export const DETAIL_VIEW_SEARCH_KEYS_TO_EXCLUDE = [
    APPLICATION_SEARCH_KEY,
    DISPLAY_TYPE_SEARCH__KEY,
    EVENT_START_KEY,
]

export const useDetailView = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const isDetailViewDisplayedAsPopup =
        searchParams.get(DISPLAY_TYPE_SEARCH__KEY) === SupportedDisplayType.POPUP
    const isDetailViewOpen = Object.values(SupportedDetailView).some((key) => searchParams.has(key))

    const openNewDetailView = (
        key: SupportedDetailView,
        value: string,
        params: {
            resetHistory?: boolean
            stateValues?: Record<string, any>
            searchParams?: { key: string; value: string }[]
        } = {}
    ) => {
        const updatedSearchParams = params.resetHistory ? new URLSearchParams() : searchParams
        updatedSearchParams.set(key, value)

        if (params.searchParams) {
            params.searchParams.forEach((param) => {
                updatedSearchParams.set(param.key, param.value)
            })
        }

        navigate(
            {
                search: updatedSearchParams.toString(),
            },
            {
                replace: true,
                state: {
                    ...params.stateValues,
                },
            }
        )
    }

    const closeDetailView = useCallback(() => {
        const searchKeys = Array.from(searchParams.keys()).filter(
            (key) => !DETAIL_VIEW_SEARCH_KEYS_TO_EXCLUDE.includes(key)
        )
        const lastKey = searchKeys.pop()

        if (!lastKey) {
            return
        }

        if (searchKeys.length === 0) {
            DETAIL_VIEW_SEARCH_KEYS_TO_EXCLUDE.forEach((key) => searchParams.delete(key))
        }

        searchParams.delete(lastKey)

        navigate(
            {
                search: searchParams.toString(),
            },
            {
                replace: true,
            }
        )
    }, [navigate, searchParams])

    const openUserDetailView = (
        userId: string,
        options?: {
            resetHistory?: boolean
        }
    ) => openNewDetailView(SupportedDetailView.USER, userId, options)

    const openGroupDetailView = (
        groupId: string,
        options?: {
            resetHistory?: boolean
        }
    ) => {
        if ([MY_CHURCH_GROUP_ID, groupRepository.homeGroupId].includes(groupId)) {
            return
        }

        openNewDetailView(SupportedDetailView.GROUP, groupId, options)
    }

    const openFeedDetailView = (
        creatorId: string,
        options?: {
            resetHistory?: boolean
        }
    ) => {
        openNewDetailView(SupportedDetailView.FEED, creatorId, options)
    }

    const openGroupMembersView = ({ groupMemberCount }: { groupMemberCount: number }) => {
        openNewDetailView(SupportedDetailView.GROUP_MEMBERS, 'true', {
            stateValues: {
                groupMemberCount,
            },
        })
    }

    const openCommentsListView = ({
        postId,
        post,
        resetHistory,
    }: XOr<{
        postId: string
        post: Post
    }> & {
        resetHistory?: boolean
    }) => {
        openNewDetailView(SupportedDetailView.COMMENTS, postId ?? post.id, {
            resetHistory,
            stateValues: {
                post,
            },
        })
    }

    const openLikesListView = (
        postId: string,
        options: {
            resetHistory?: boolean
        } = {}
    ) => {
        openNewDetailView(SupportedDetailView.LIKES, postId, {
            resetHistory: options.resetHistory,
        })
    }

    const openPostDetailView = (
        postId: string,
        {
            highlightedCommentId,
        }: {
            highlightedCommentId?: string
        } = {}
    ) => {
        openNewDetailView(SupportedDetailView.POST, postId, {
            resetHistory: true,
            stateValues: {
                highlightedCommentId,
            },
        })
    }

    const openGroupAccessRequests = ({
        groupId,
        group,
        resetHistory,
    }: XOr<{ groupId: string | 'all'; group: Group }> & { resetHistory?: boolean }) => {
        openNewDetailView(SupportedDetailView.GROUP_ACCESS_REQUESTS, groupId ?? group.id, {
            resetHistory,
            stateValues: {
                group,
            },
        })
    }

    const openAppAccessRequestsView = () => {
        openNewDetailView(SupportedDetailView.APP_ACCESS_REQUESTS, '1', {
            resetHistory: true,
        })
    }

    const openEventDetailView = ({
        sharedEvent,
        event,
        resetHistory,
    }: XOr<{
        sharedEvent: { id: string; start: DateTime }
        event: Event
    }> & {
        resetHistory?: boolean
    }) => {
        const eventId = sharedEvent?.id ?? event?.id
        const start = (sharedEvent?.start ?? event?.start)!.toJSDate().toISOString()
        openNewDetailView(SupportedDetailView.EVENT, eventId!, {
            resetHistory,
            searchParams: [{ key: EVENT_START_KEY, value: start }],
        })
    }

    const openAttendancesView = (
        eventId: string,
        eventStart: DateTime,
        options: {
            resetHistory?: boolean
        } = {}
    ) => {
        openNewDetailView(SupportedDetailView.ATTENDANCES, eventId, {
            resetHistory: options.resetHistory,
            searchParams: [{ key: EVENT_START_KEY, value: eventStart.toJSDate().toISOString() }],
        })
    }

    return {
        closeDetailView,
        openAppAccessRequestsView,
        openCommentsListView,
        openEventDetailView,
        openAttendancesView,
        openFeedDetailView,
        openGroupAccessRequests,
        openGroupDetailView,
        openGroupMembersView,
        openLikesListView,
        openPostDetailView,
        openUserDetailView,
        isDetailViewOpen,
        isDetailViewDisplayedAsPopup,
    }
}
