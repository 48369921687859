import { SessionRepository } from '../authentication/SessionRepository'
import { WebAppHttpRepository } from '../common/WebAppHttpRepository'
import { Post } from './Post'
import { LikeType } from './likes/LikeType'
import { PostLikeInfo } from './likes/PostLikeInfo'
import { CreatePostBody } from './CreatePostBody'
import { Like } from './likes/Like'
import { EditPostBody } from './EditPostBody'
import { PostDeletedEvent } from './events'

export type OnPostUpdatedObserver = (post: Post) => void

export class PostRepository extends WebAppHttpRepository {
    private observers: OnPostUpdatedObserver[] = []

    constructor(private readonly sessionRepository: SessionRepository) {
        super()
    }

    addObserver(observer: OnPostUpdatedObserver) {
        this.observers.push(observer)
    }

    removeObserver(observer: OnPostUpdatedObserver) {
        this.observers = this.observers.filter((currentObserver) => currentObserver !== observer)
    }

    async createPost(post: CreatePostBody): Promise<Post> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.post<any>(`${this.apiUrl}/api/v7/posts`, post.toFormData())
        )

        return Post.fromJSON(response)
    }

    async getPost(postId: string): Promise<Post> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any[]>(`${this.apiUrl}/api/v7/posts/${postId}`)
        )

        return Post.fromJSON(response)
    }

    async getPosts(
        params: { groupId?: string; limit: number; from?: Date },
        abortSignal?: AbortSignal
    ): Promise<Post[]> {
        const fromParam = params.from ? `&from=${params.from.toISOString()}` : ''
        const groupIdParam = params.groupId ? `&groupId=${params.groupId}` : ''
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any[]>(
                `${this.apiUrl}/api/v7/posts?limit=${params.limit}${fromParam}${groupIdParam}`,
                abortSignal
            )
        )

        return response.map(Post.fromJSON)
    }

    async getLikes(postId: string): Promise<Like[]> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.get<any[]>(`${this.apiUrl}/api/v2/posts/${postId}/likes`)
        )

        return response.map(Like.fromJSON)
    }

    async likePost(post: Post, like?: LikeType): Promise<Post> {
        const likes = post.likes?.updateWith(like) ?? new PostLikeInfo(like, 1, [], undefined)
        const updatedPost = post.cloneWith({ likes })

        this.observers.forEach((observer) => observer(updatedPost))

        try {
            await this.sessionRepository.withAccessToken(async () => {
                if (like) {
                    this.post(`${this.apiUrl}/api/v1/posts/${post.id}/like`, { type: like })
                } else {
                    this.delete(`${this.apiUrl}/api/v1/posts/${post.id}/like`)
                }
            })

            return updatedPost
        } catch (error) {
            this.observers.forEach((observer) => observer(post))
            return post
        }
    }

    async updatePost(post: EditPostBody): Promise<Post> {
        const response = await this.sessionRepository.withAccessToken(async () =>
            this.patch<Post>(`${this.apiUrl}/api/v7/posts/${post.id}`, post.toFormData())
        )
        return Post.fromJSON(response)
    }

    async deletePost(postId: string): Promise<void> {
        await this.sessionRepository.withAccessToken(async () =>
            this.delete(`${this.apiUrl}/api/v4/posts/${postId}`)
        )

        document.dispatchEvent(new PostDeletedEvent(postId))
    }
}
