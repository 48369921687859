import {
    Box,
    List as MuiList,
    ListItem,
    ListItemButton as MuiListItemButton,
    ListItemIcon as MuiListItemIcon,
    ListItemText,
    styled,
    Typography,
} from '@mui/material'
import { darkGrey, grey_1, grey_3 } from 'shared/lib/theme/Theme'
import { ReactComponent as ShareIcon } from 'shared/lib/assets/svg/share.svg'
import { useTranslation } from 'shared/lib/i18n'
import { Post } from '../posts/Post'
import { CreatePostPopup } from '../posts/createpostpopup/CreatePostPopup'
import { useState } from 'react'
import { MenuPopup } from 'shared/lib/components/MenuPopup'
import { ReactComponent as GroupIcon } from 'shared/lib/assets/svg/group.svg'
import { ReactComponent as LinkIcon } from 'shared/lib/assets/svg/link.svg'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../routing/paths'

interface Properties {
    post: Post
    onPostShared: (post: Post) => void
}

export const SharePostButton = ({ post, onPostShared }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const [isCreatePostPopupVisible, setIsCreatePostPopupVisible] = useState<boolean>(false)
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)

    const onShareButtonClicked = (event: any) => {
        setAnchor(event.currentTarget)
    }

    const onShareInGroupClicked = () => {
        setAnchor(null)
        setIsCreatePostPopupVisible(true)
    }

    const onCopyPostLinkClicked = async () => {
        setAnchor(null)
        await navigator.clipboard.writeText(post.shareUrl)
    }

    const onSubmitButtonClicked = (createdPost: Post) => {
        setIsCreatePostPopupVisible(false)

        if (post.groupId === createdPost.groupId) {
            onPostShared(post)
            return
        }

        navigate(Paths.GROUP_TIME_LINE.replace(':groupId', createdPost.groupId), { replace: true })
    }

    return (
        <>
            <ShareContainer onClick={onShareButtonClicked}>
                <Share />
                <ShareText variant="body2">{translations('share')}</ShareText>
            </ShareContainer>

            <MenuPopup
                anchor={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                popupMarginTop={'8px'}
                arrowRightPosition={'16px'}
                onPopupClosed={() => setAnchor(null)}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton onClick={onCopyPostLinkClicked}>
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemText primary={translations('copy_post_link')} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        {post.sharedPostId === undefined && (
                            <ListItemButton onClick={onShareInGroupClicked}>
                                <ListItemIcon>
                                    <GroupIcon sx={{ fill: darkGrey }} />
                                </ListItemIcon>
                                <ListItemText primary={translations('share_in_group')} />
                            </ListItemButton>
                        )}
                    </ListItem>
                </List>
            </MenuPopup>

            {isCreatePostPopupVisible && (
                <CreatePostPopup
                    onCloseButtonClicked={() => setIsCreatePostPopupVisible(false)}
                    onSubmitButtonClicked={onSubmitButtonClicked}
                    postToShare={post}
                />
            )}
        </>
    )
}

const ShareContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(1),
    cursor: 'pointer',
    height: '100%',
    borderRadius: '24px',
    padding: theme.spacing(1),
    '&:hover': {
        backgroundColor: grey_3,
    },
}))

const Share = styled(ShareIcon)(() => ({
    fill: grey_1,
}))

const ShareText = styled(Typography)(() => ({
    fontSize: '12px',
    margin: 'auto',
}))

const List = styled(MuiList)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(2),
}))

const ListItemButton = styled(MuiListItemButton)(() => ({
    borderRadius: '16px',
}))

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
    minWidth: 'unset',
    marginRight: theme.spacing(2),
    '& svg': {
        height: '20px',
        width: '20px',
    },
}))
