import { useState } from 'react'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { MAX_PDF_SIZE_BYTES, MAX_UPLOAD_SIZE_BYTES } from 'shared/lib/common/Constants'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { LocalVideo } from 'shared/lib/forms/LocalVideo'
import { MediaItem } from 'shared/lib/models/MediaItem'
import theme from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, postRepository } from '../../index'
import { EditPostBody } from '../EditPostBody'
import { Post } from '../Post'
import { PostPopup } from './PostPopup'
import { getFirstValidUrl } from 'shared/lib/utils/StringUtils'

interface EditPostPopupProperties {
    postToEdit: Post
    onCloseButtonClicked: () => void
    onSubmitButtonClicked: (post: Post) => void
}

export const EditPostPopup = ({ postToEdit, ...properties }: EditPostPopupProperties) => {
    const translations = useTranslation()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [message, setMessage] = useState(postToEdit.message || '')
    const [images, setImages] = useState(postToEdit.images)
    const [videos, setVideos] = useState(postToEdit.videos)
    const [localImagesAndVideos, setLocalImagesAndVideos] = useState<LocalFile[]>([])
    const [pdf, setPdf] = useState(postToEdit.pdfs.at(0))
    const [localPdfFile, setLocalPdfFile] = useState<LocalPdf | undefined>(undefined)
    const [linkPreview, setLinkPreview] = useState(postToEdit.linkPreviews.at(0))
    const [sharedPost, setSharedPost] = useState(postToEdit.sharedPost)
    const [sharedPostId, setSharedPostId] = useState(postToEdit.sharedPostId)
    const [sharedGroup, setSharedGroup] = useState(postToEdit.sharedGroup)
    const [sharedGroupId, setSharedGroupId] = useState(postToEdit.sharedGroupId)
    const [sharedEvent, setSharedEvent] = useState(postToEdit.sharedEvent)
    const [hasDeletedLinkPreview, setHasDeletedLinkPreview] = useState<boolean>(
        getFirstValidUrl(postToEdit.message) !== undefined && postToEdit.linkPreviews.length === 0
    )
    const [isAlertDialogVisible, setIsAlertDialogVisible] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>()

    const getTotalsSizeFromSelectedItems = (): number => {
        if (localPdfFile) {
            return localPdfFile.file.size
        }

        return localImagesAndVideos.reduce((count, localFile) => {
            return count + localFile.file.size
        }, 0)
    }

    const isSizeToBig = (): boolean => {
        return (
            getTotalsSizeFromSelectedItems() >
            (localPdfFile ? MAX_PDF_SIZE_BYTES : MAX_UPLOAD_SIZE_BYTES)
        )
    }

    const hasChangedMessage = JSON.stringify(postToEdit?.message || '') !== JSON.stringify(message)
    const hasChangedLinkPreview = postToEdit?.linkPreviews[0] !== linkPreview
    const hasChangedPdf = postToEdit?.pdfs[0] !== pdf
    const hadChangedVideos =
        postToEdit?.videos.length !== 0 &&
        !postToEdit?.videos.every((video) => {
            return videos.find((v) => v.key === video.key)
        })
    const hasChangedImages = JSON.stringify(postToEdit?.images) !== JSON.stringify(images)
    const hasAddedLocalFiles = localImagesAndVideos.length > 0
    const hasAddedLocalPdf = localPdfFile !== undefined
    const hasChangedSharedPost =
        postToEdit?.sharedPost !== sharedPost || postToEdit?.sharedPostId !== sharedPostId
    const hasChangedSharedGroup =
        postToEdit?.sharedGroup !== sharedGroup || postToEdit?.sharedGroupId !== sharedGroupId
    const hasChangedEvent = postToEdit?.sharedEvent !== sharedEvent

    const hasChangedPost =
        hasChangedMessage ||
        hasChangedLinkPreview ||
        hasChangedPdf ||
        hadChangedVideos ||
        hasChangedImages ||
        hasAddedLocalFiles ||
        hasAddedLocalPdf ||
        hasChangedSharedPost ||
        hasChangedSharedGroup ||
        hasChangedEvent

    const canContinue =
        hasChangedPost &&
        (message.length > 0 ||
            localImagesAndVideos.length > 0 ||
            images.length > 0 ||
            videos.length > 0 ||
            Boolean(localPdfFile) ||
            Boolean(pdf) ||
            Boolean(sharedPostId) ||
            Boolean(sharedGroupId) ||
            Boolean(sharedEvent)) &&
        !isSizeToBig()
    const selectedPdf = localPdfFile ? localPdfFile : pdf

    const onMessageChanged = (message: string) => {
        if (message === '') {
            setHasDeletedLinkPreview(false)
        }

        setMessage(message)
    }

    const onMediaItemsSelected = (images: LocalImage[], videos: LocalVideo[]) => {
        const existingVideos = LocalFile.filterOnType(localImagesAndVideos, LocalVideo)
        const existingImages = LocalFile.filterOnType(localImagesAndVideos, LocalImage)

        setLocalImagesAndVideos([...existingVideos, ...videos, ...existingImages, ...images])
    }

    const onDeleteButtonImageOrVideoClicked = (mediaItem: MediaItem) => {
        setVideos(videos.filter((video) => video.key !== mediaItem.id))
        setImages(images.filter((image) => image.key !== mediaItem.id))
        setLocalImagesAndVideos(localImagesAndVideos.filter((file) => file.id !== mediaItem.id))
    }

    const onPdfSelected = async (inputtedFiles: LocalFile[]) => {
        if (inputtedFiles[0] instanceof LocalPdf) {
            setPdf(undefined)
            setLocalPdfFile(inputtedFiles[0])
        }
    }
    const onDeleteButtonPdfClicked = () => {
        setPdf(undefined)
        setLocalPdfFile(undefined)
    }

    const onDeleteButtonLinkPreviewClicked = () => {
        setLinkPreview(undefined)
        setHasDeletedLinkPreview(true)
    }

    const onDeleteButtonSharedPostClicked = () => {
        setSharedPost(undefined)
        setSharedPostId(undefined)
    }

    const onDeleteButtonSharedGroupClicked = () => {
        setSharedGroup(undefined)
        setSharedGroupId(undefined)
    }

    const onCloseButtonClicked = () => {
        if (hasChangedPost) {
            setIsAlertDialogVisible(true)
            return
        }

        properties.onCloseButtonClicked()
    }

    const onContinueButtonClicked = async () => {
        if (postToEdit === undefined) {
            return
        }

        setIsLoading(true)

        try {
            const post = await postRepository.updatePost(
                new EditPostBody(
                    postToEdit?.id,
                    message,
                    images,
                    videos,
                    localImagesAndVideos,
                    linkPreview,
                    pdf,
                    localPdfFile,
                    sharedPostId,
                    sharedGroupId,
                    sharedEvent
                )
            )

            properties.onSubmitButtonClicked(post)
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <PostPopup
                isLoading={isLoading}
                isGroupSelectorEnabled={false}
                isSizeToBig={isSizeToBig()}
                canContinue={canContinue}
                selectedGroupId={postToEdit?.groupId}
                hasDeletedLinkPreview={hasDeletedLinkPreview}
                message={message}
                continueButtonText={translations('save')}
                sharedPostId={sharedPostId}
                sharedGroupId={sharedGroupId}
                sizeFromContainingItems={getTotalsSizeFromSelectedItems()}
                linkPreview={linkPreview}
                pdfFile={selectedPdf}
                mediaItems={MediaItem.mapToMediaItems(
                    {
                        images: images,
                        videos: videos,
                        localImages: LocalFile.filterOnType(localImagesAndVideos, LocalImage),
                        localVideos: LocalFile.filterOnType(localImagesAndVideos, LocalVideo),
                    },
                    fileRepository
                )}
                sharedPost={sharedPost}
                sharedGroup={sharedGroup}
                sharedEvent={sharedEvent}
                onMessageChanged={onMessageChanged}
                onLinkPreviewUpdated={setLinkPreview}
                onDeleteButtonLinkPreviewClicked={onDeleteButtonLinkPreviewClicked}
                onMediaItemsSelected={onMediaItemsSelected}
                onDeleteImageOrVideoClicked={onDeleteButtonImageOrVideoClicked}
                onPdfSelected={onPdfSelected}
                onDeleteButtonPdfClicked={onDeleteButtonPdfClicked}
                onDeleteSharedPostClicked={onDeleteButtonSharedPostClicked}
                onDeleteSharedGroupClicked={onDeleteButtonSharedGroupClicked}
                onDeleteSharedEventClicked={() => setSharedEvent(undefined)}
                onCloseButtonClicked={onCloseButtonClicked}
                onContinueButtonClicked={onContinueButtonClicked}
            />

            <AlertDialog
                isVisible={isAlertDialogVisible}
                titleIcon={<AlertIcon fill={theme.palette.primary.main} />}
                title={translations('changes_are_not_saved')}
                message={translations('are_you_sure_concept_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setIsAlertDialogVisible(false)}
                continueButtonType={ButtonType.NONE}
                continueButtonTitle={translations('undo_changes')}
                onContinueButtonClicked={properties.onCloseButtonClicked}
            />

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
