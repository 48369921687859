import { SVGProps } from 'react'

export const CalendarIconFilled = (properties: SVGProps<any>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="current"
        {...properties}
    >
        <path d="M3.16667 -0.000976562C1.41777 -0.000976562 0 1.41679 0 3.16569L16 3.16602C16 1.41711 14.5822 -0.000976562 12.8333 -0.000976562H3.16667Z" />
        <path d="M16 4.16602L0 4.16569V12.835C0 14.5839 1.41777 16.0017 3.16667 16.0017H12.8333C14.5822 16.0017 16 14.5839 16 12.835V4.16602Z" />
    </svg>
)
