import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { VirtuosoHandle } from 'react-virtuoso'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { eventRepository, notificationRepository, postRepository } from '..'
import { CommentCreatedEvent, CommentEventKey } from '../comments/events'
import { useDetailView } from '../common/detailView/hooks'
import { Group, MY_CHURCH_GROUP_ID } from '../groups/Group'
import { NotificationType } from '../notifications/NotificationType'
import { Post } from '../posts/Post'
import { LikeType } from '../posts/likes/LikeType'
import {
    CreatePostViewModel,
    GroupHeaderViewModel,
    LoadPostsErrorViewModel,
    PostItemViewModel,
    PostLoadingViewModel,
    PostViewModel,
} from './PostViewModel'
import { MarkedAsReadEvent, PostEvent } from './events'
import {
    GroupAccessRequestedEvent,
    GroupAccessWithdrawnEvent,
    GroupEventKey,
} from '../groups/events'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { EventAttendanceUpdatedEvent, EventEventKey, EventUpdatedEvent } from '../events/events'

interface UsePostsProperties {
    selectedGroup: Group
    homeGroupId?: string
    listRef: VirtuosoHandle | null
}

export const usePosts = ({ selectedGroup, homeGroupId, ...properties }: UsePostsProperties) => {
    const isMobile = useIsMobile()

    const { openCommentsListView } = useDetailView()

    const abortControllerRef = useRef<AbortController>()

    const [posts, setPosts] = useState<Post[]>([])
    const [viewModels, setViewModels] = useState<PostViewModel[]>([])
    const [postToEdit, setPostToEdit] = useState<Post | undefined>(undefined)
    const [postIdToDelete, setPostIdToDelete] = useState<string | undefined>()
    const [isDeletingPost, setIsDeletingPost] = useState(false)
    const [error, setError] = useState<Error | undefined>()

    const canCreatePosts = selectedGroup.canCreatePosts
    const selectedGroupId = selectedGroup.id
    const selectedGroupName = selectedGroup.name
    const isMyChurchGroup = selectedGroupId === MY_CHURCH_GROUP_ID

    const onPostUpdated = useCallback((updatedPost: Post) => {
        setPostToEdit(undefined)

        setPosts((prevPosts) => {
            const index = prevPosts.findIndex((post) => post.id === updatedPost.id)
            const updatedPosts = [...prevPosts]
            updatedPosts[index] = updatedPost

            setViewModels((prevViewModels) => {
                const viewModelIndex = prevViewModels.findIndex((viewModel) => {
                    return (
                        viewModel instanceof PostItemViewModel &&
                        viewModel.post.id === updatedPost.id
                    )
                })

                if (viewModelIndex === -1) return prevViewModels

                const viewModel = prevViewModels[viewModelIndex] as PostItemViewModel
                const updatedViewModels = [...prevViewModels]
                updatedViewModels[viewModelIndex] = viewModel.cloneWith({
                    post: updatedPost,
                    isCreatingComment: false,
                })

                return updatedViewModels
            })

            return updatedPosts
        })
    }, [])

    const markAllPostsAsReadIfNeeded = useCallback((group: Group, fromDate: DateTime) => {
        notificationRepository
            .markNotificationsAsRead([
                {
                    type: NotificationType.POST_PLACED_IN_GROUP,
                    groupId: group.id,
                    fromDate,
                },
            ])
            .then(() => {
                document.dispatchEvent(new MarkedAsReadEvent(group.id, group.numberOfUnreadPosts))
            })
    }, [])

    const markPostAsReadIfNeeded = useCallback(
        (post: Post) => {
            if (!post.postPlacedInGroupNotificationId) {
                return
            }

            notificationRepository
                .markNotificationAsRead(post.postPlacedInGroupNotificationId)
                .then(() => {
                    onPostUpdated(post.cloneWith({ isRead: true }))
                    document.dispatchEvent(new MarkedAsReadEvent(post.groupId, 1))
                })
        },
        [onPostUpdated]
    )

    const updateEventAttendance = useCallback(
        async (post: Post, state: EventAttendanceState) => {
            const sharedEvent = post.sharedEvent

            if (!sharedEvent) {
                return
            }

            await eventRepository.updateAttendance({
                eventId: sharedEvent.id,
                eventStart: sharedEvent.start,
                oldState:
                    sharedEvent.signedInUserAttendanceState || EventAttendanceState.NOT_RESPONDED,
                state,
            })
            markPostAsReadIfNeeded(post)
        },
        [markPostAsReadIfNeeded]
    )

    const onEventAttendanceUpdated = useCallback(
        (event: Event) => {
            posts.forEach((post) => {
                onPostUpdated(
                    Post.updateSharedEvent(
                        post,
                        (event as CustomEvent).detail as EventAttendanceUpdatedEvent
                    )
                )
            })
        },
        [posts, onPostUpdated]
    )

    const onEventUpdated = useCallback(
        (ev: Event) => {
            const { event } = (ev as EventUpdatedEvent).detail

            const postsWithSharedEvent = posts.filter(
                (post) =>
                    post.sharedEvent?.id === event.id &&
                    post.sharedEvent?.start.toString() === event.start.toString()
            )
            postsWithSharedEvent.forEach((post) => {
                onPostUpdated(
                    post.cloneWith({
                        sharedEvent: post.sharedEvent?.cloneWith({
                            title: event.title,
                            isAllDay: event.isAllDay,
                            canMembersRegisterAttendance: event.canMembersRegisterAttendance,
                        }),
                    })
                )
            })
        },
        [posts, onPostUpdated]
    )

    const onEventDeleted = useCallback(
        (ev: Event) => {
            const { eventId } = (ev as CustomEvent).detail
            const postsWithSharedEvent = posts.filter((post) => post.sharedEvent?.id === eventId)
            postsWithSharedEvent.forEach((post) => {
                onPostUpdated(
                    post.cloneWith({
                        sharedEvent: post.sharedEvent?.cloneWith({ isDeleted: true }),
                    })
                )
            })
        },
        [posts, onPostUpdated]
    )

    const likePost = useCallback(
        async (post: Post, likeType?: LikeType) => {
            const updatedPost = await postRepository.likePost(post, likeType)
            markPostAsReadIfNeeded(updatedPost)
        },
        [markPostAsReadIfNeeded]
    )

    const onSharedGroupUpdated = useCallback(
        (event: Event) => {
            if (
                event instanceof GroupAccessRequestedEvent ||
                event instanceof GroupAccessWithdrawnEvent
            ) {
                const group = event.detail.group
                const postsWithSharedGroup = posts.filter((post) => post.sharedGroupId === group.id)
                postsWithSharedGroup.forEach((post) => {
                    onPostUpdated(post.cloneWith({ sharedGroup: group }))
                })
            }
        },
        [posts, onPostUpdated]
    )

    const onCommentCreated = useCallback(
        (event: Event) => {
            const postId = (event as CommentCreatedEvent).detail.postId
            const post = posts.find((post) => post.id === postId)
            if (!post) return

            const updatedPost = post.cloneWith({
                numberOfComments: post.numberOfComments + 1,
            })
            onPostUpdated(updatedPost)
            openCommentsListView({ post: updatedPost })
        },
        [posts, onPostUpdated] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const onCommentDeleted = useCallback(
        (event: Event) => {
            const postId = (event as CommentCreatedEvent).detail.postId
            const post = posts.find((post) => post.id === postId)
            if (!post) return

            const updatedPost = post.cloneWith({
                numberOfComments: post.numberOfComments - 1,
            })
            onPostUpdated(updatedPost)
        },
        [posts, onPostUpdated]
    )

    const hasFetchedAllPosts = useCallback(
        (post?: Post) => {
            const groupIdOfLastPost = isMyChurchGroup ? homeGroupId : selectedGroupId
            return post?.groupId === groupIdOfLastPost && post?.isFirstInGroup === true
        },
        [isMyChurchGroup, selectedGroupId, homeGroupId]
    )

    const getPosts = useCallback(
        async (params?: { fromDate: Date }) => {
            const fromDate = params?.fromDate || DateTime.now().endOf('day').toJSDate()

            try {
                const newPosts = await postRepository.getPosts(
                    {
                        groupId: isMyChurchGroup ? undefined : selectedGroupId,
                        limit: 10,
                        from: fromDate,
                    },
                    abortControllerRef.current?.signal
                )

                const reloadPosts = () => {
                    setPosts([])
                    setViewModels([])
                    getPosts()
                }

                setPosts((prevPosts) => {
                    const allPosts = params ? [...prevPosts, ...newPosts] : newPosts
                    let viewModels: PostViewModel[] = []

                    if (!isMobile && ![homeGroupId, MY_CHURCH_GROUP_ID].includes(selectedGroupId)) {
                        viewModels.push(
                            new GroupHeaderViewModel({
                                id: selectedGroupId,
                                name: selectedGroupName,
                            })
                        )
                    }

                    if (canCreatePosts) {
                        viewModels.push(
                            new CreatePostViewModel(
                                selectedGroupId === MY_CHURCH_GROUP_ID
                                    ? undefined
                                    : selectedGroupId,
                                reloadPosts
                            )
                        )
                    }

                    viewModels.push(
                        ...allPosts.map(
                            (post) =>
                                new PostItemViewModel(
                                    isMyChurchGroup,
                                    false,
                                    post,
                                    updateEventAttendance,
                                    likePost,
                                    () => {},
                                    reloadPosts,
                                    setPostToEdit,
                                    setPostIdToDelete
                                )
                        )
                    )

                    if (!hasFetchedAllPosts(allPosts[allPosts.length - 1])) {
                        viewModels.push(new PostLoadingViewModel())
                    }

                    setViewModels(viewModels)

                    return allPosts
                })
            } catch (error: any) {
                if (error.name === 'CanceledError') {
                    return
                }

                setError(error)
                setViewModels([new LoadPostsErrorViewModel()])
                return
            }
        },
        [
            isMyChurchGroup,
            isMobile,
            selectedGroupId,
            selectedGroupName,
            homeGroupId,
            canCreatePosts,
            updateEventAttendance,
            likePost,
            hasFetchedAllPosts,
        ]
    )

    const loadMorePostsIfNeeded = () => {
        const lastPost = posts[posts.length - 1]
        const hasFetchedAll = hasFetchedAllPosts(lastPost)

        if (!lastPost || hasFetchedAll) {
            return
        }

        getPosts({ fromDate: lastPost.createdAt.toJSDate() })
    }

    const deletePost = async () => {
        if (!postIdToDelete) {
            return
        }

        try {
            setIsDeletingPost(true)

            await postRepository.deletePost(postIdToDelete)

            const updatedPosts = posts.filter((post) => post.id !== postIdToDelete)
            const updatedViewModels = viewModels.filter((viewModel) => {
                return (
                    !(viewModel instanceof PostItemViewModel) ||
                    viewModel.post.id !== postIdToDelete
                )
            })

            setPosts(updatedPosts)
            setViewModels(updatedViewModels)
        } catch (error: any) {
            setError(error)
        } finally {
            setIsDeletingPost(false)
            setPostIdToDelete(undefined)
        }
    }

    useEffect(() => {
        const markAsRead = (event: Event) => {
            markPostAsReadIfNeeded((event as CustomEvent).detail)
        }

        postRepository.addObserver(onPostUpdated)
        document.addEventListener(PostEvent.MARK_AS_READ, markAsRead)
        document.addEventListener(CommentEventKey.COMMENT_CREATED, onCommentCreated)
        document.addEventListener(CommentEventKey.COMMENT_DELETED, onCommentDeleted)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)
        document.addEventListener(EventEventKey.EVENT_UPDATED, onEventUpdated)
        document.addEventListener(EventEventKey.EVENT_ATTENDANCE_UPDATED, onEventAttendanceUpdated)
        document.addEventListener(EventEventKey.EVENT_DELETED, onEventDeleted)

        return () => {
            postRepository.removeObserver(onPostUpdated)
            document.removeEventListener(PostEvent.MARK_AS_READ, markAsRead)
            document.removeEventListener(CommentEventKey.COMMENT_CREATED, onCommentCreated)
            document.removeEventListener(CommentEventKey.COMMENT_DELETED, onCommentDeleted)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)
            document.removeEventListener(EventEventKey.EVENT_UPDATED, onEventUpdated)
            document.removeEventListener(
                EventEventKey.EVENT_ATTENDANCE_UPDATED,
                onEventAttendanceUpdated
            )
            document.removeEventListener(EventEventKey.EVENT_DELETED, onEventDeleted)
        }
    }, [
        onPostUpdated,
        onSharedGroupUpdated,
        markPostAsReadIfNeeded,
        onCommentCreated,
        onCommentDeleted,
        onEventUpdated,
        onEventAttendanceUpdated,
        onEventDeleted,
    ])

    useEffect(() => {
        if (!homeGroupId) {
            return
        }

        properties.listRef?.scrollToIndex({ index: 0, behavior: 'auto' })

        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        setError(undefined)
        setPosts([])
        setViewModels([])
        getPosts()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [homeGroupId, properties.listRef, getPosts])

    return {
        isDeletingPost,
        viewModels,
        postToEdit,
        postIdToDelete,
        error,
        setPostToEdit,
        setPostIdToDelete,
        loadMorePostsIfNeeded,
        onPostUpdated,
        deletePost,
        markAllPostsAsReadIfNeeded,
    }
}
