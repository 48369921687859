import { Box, Link, Typography, styled } from '@mui/material'
import { grey_3 } from 'shared/lib/theme/Theme'
import { useTranslation } from 'shared/lib/i18n'
import { churchRepository } from '../..'

interface Properties {
    isReadOnly?: boolean
    hasWhiteBackground?: boolean
}

export const UnsupportedContentView = (properties: Properties) => {
    const translations = useTranslation()

    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const church = churchRepository.church

    return (
        <Container bgcolor={hasWhiteBackground ? 'white' : grey_3}>
            <TextView variant="body1">{translations('unsupported_web_app_content')}</TextView>
            <LinkButton
                href={church?.appOrPlayStoreUrl}
                target="_blank"
                rel="noreferrer"
                sx={{ pointerEvents: properties.isReadOnly ? 'none' : 'auto' }}
            >
                {translations('download')}
            </LinkButton>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    borderRadius: '16px',
    flexGrow: 1,
}))

const TextView = styled(Typography)(() => ({
    wordBreak: 'break-word',
}))

const LinkButton = styled(Link)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: 'white !important',
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    borderRadius: '16px',
    fontSize: '14px',
    fontWeight: 700,
}))
