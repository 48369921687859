import { useCallback, useEffect, useRef, useState } from 'react'
import { EventAttendanceUpdatedEvent, EventEventKey } from '../events/events'
import {
    GroupAccessRequestedEvent,
    GroupAccessWithdrawnEvent,
    GroupEventKey,
    GroupUpdatedEvent,
} from '../groups/events'
import { postRepository } from '../index'
import { Post } from './Post'

export const usePost = (postFromState?: Post) => {
    const isLoadingRef = useRef(false)

    const [error, setError] = useState<Error | undefined>()
    const [post, setPost] = useState(postFromState)

    const sharedGroupId = post?.sharedGroup?.id

    const getPost = useCallback((postId: string) => {
        if (isLoadingRef.current) {
            return
        }

        isLoadingRef.current = true

        postRepository
            .getPost(postId)
            .then(setPost)
            .catch(setError)
            .finally(() => (isLoadingRef.current = false))
    }, [])

    const onGroupUpdated = useCallback(
        (event: Event) => {
            const { id, name } = (event as GroupUpdatedEvent).detail

            if (post?.groupId === id) {
                setPost((post) => post?.cloneWith({ groupName: name }))
            }
        },
        [post?.groupId]
    )

    const onSharedGroupUpdated = useCallback(
        (event: Event) => {
            if (
                event instanceof GroupAccessRequestedEvent ||
                event instanceof GroupAccessWithdrawnEvent
            ) {
                const group = event.detail.group

                if (sharedGroupId !== group.id) return

                setPost((prevState) => prevState?.cloneWith({ sharedGroup: group }))
            }
        },
        [sharedGroupId, setPost]
    )

    const onEventAttendanceUpdated = useCallback(
        (event: Event) => {
            if (!post) return

            setPost(
                Post.updateSharedEvent(
                    post,
                    (event as CustomEvent).detail as EventAttendanceUpdatedEvent
                )
            )
        },
        [post]
    )

    useEffect(() => {
        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)
        document.addEventListener(EventEventKey.EVENT_ATTENDANCE_UPDATED, onEventAttendanceUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)
            document.removeEventListener(
                EventEventKey.EVENT_ATTENDANCE_UPDATED,
                onEventAttendanceUpdated
            )
        }
    }, [onGroupUpdated, onSharedGroupUpdated, onEventAttendanceUpdated])

    return { isLoading: isLoadingRef.current, error, post, setPost, getPost }
}
