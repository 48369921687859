import validator from 'validator'
import path from 'path'
import { parse } from 'tldts'
import { SupportedTopLevelDomains } from './TopLevelDomains'

export function escapeMetaCharacters(text: string): string {
    return text.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
}

export function isValidEmail(value: string) {
    const emailRegex = RegExp(
        /^[A-Za-z0-9!#$%&'*+-/=?^_`{|}~]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    return emailRegex.test(value)
}

export function isValidUrl(value: string): boolean {
    if (!value) return false

    const result = parse(value)

    const topLevelDomains = result.domain === null ? [] : result?.publicSuffix?.split('.') ?? []
    const isValidTopLeveldomain = topLevelDomains.some((domain) =>
        SupportedTopLevelDomains.includes(domain)
    )

    return validator.isURL(value) && isValidTopLeveldomain
}

export function getFirstValidUrl(value?: string): string | undefined {
    const words = value?.split(/(\s+)|(\W+\s+)|\W+$/) || []

    return words.filter((word) => isValidUrl(word) && !isValidEmail(word))[0]
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function getFirstCapital(str: string): string {
    for (let i = 0; i < str.length; i++) {
        if (/[A-Z]/.test(str[i])) {
            return str[i]
        }
    }

    return ''
}

export function getPathExtension(str: string): string {
    return path.extname(str)
}

export function getPathFileNameWithoutExtension(str: string): string {
    return path.basename(str, getPathExtension(str))
}

export function parsePhoneNumber(value: string): string {
    return value.replace(/[A-Za-z]/g, '')
}

export function generateRandomId(length: number): string {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    return Array.from({ length }, () =>
        charset.charAt(Math.floor(Math.random() * charset.length))
    ).join('')
}

export function removeDiacritics(str: string): string {
    return str.normalize('NFD')
}

export const getFullName = (user: { firstName: string; lastName: string }) => {
    return `${user.firstName} ${user.lastName}`.trim()
}

export const isShareUserUrl = (url: string): boolean => {
    const matches = url.toString().match(/donkeymobile\.com\/share\/.+\?userId=[a-z0-9]+/gi)

    return !!matches?.length
}

export const stripMarkdown = (value: string) => {
    return value
        .replace(/\[([^\]]+)\]\((['"]?)([^"')]+)\1\)/g, '$3')
        .replace(/\*/g, '')
        .replace(/_/g, '')
        .replace(/~/g, '')
        .replace(/`/g, '')
}
