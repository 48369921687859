import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { sessionRepository } from '..'
import { EventAttendanceUpdatedEvent } from './events'

export function handleEventAttendanceUpdatedEvent(params: {
    eventId: string
    eventStart: string
    event: EventAttendanceUpdatedEvent
    attendances: EventAttendance[]
}): EventAttendance[] {
    const signedInUser = sessionRepository.signedInUser
    const detail = params.event.detail

    if (
        detail.eventId !== params.eventId ||
        detail.eventStart.toJSDate().toISOString() !== params.eventStart ||
        signedInUser === undefined
    ) {
        return params.attendances
    }

    const newAttendances = [...params.attendances]
    const index = params.attendances.findIndex(
        (attendance) => attendance.userId === signedInUser?.id
    )

    const updatedAttendance = new EventAttendance(
        signedInUser.id,
        signedInUser.firstName,
        signedInUser.lastName,
        signedInUser.image!,
        signedInUser.functions,
        detail.state
    )

    if (index === -1) {
        newAttendances.push(updatedAttendance)
    } else {
        newAttendances[index] = updatedAttendance
    }

    return newAttendances
}
