import { Image } from '../models/Image'
import { EventAttendanceState } from './EventAttendanceState'

export class EventAttendance {
    constructor(
        readonly userId: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly image: Image,
        readonly functions: string[],
        readonly state: EventAttendanceState
    ) {}

    static fromJSON(json: any): EventAttendance {
        return new EventAttendance(
            json.userId,
            json.firstName,
            json.lastName,
            Image.fromResponse(json.image),
            json.functions || [],
            json.state
        )
    }
}
