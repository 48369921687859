import { DateTime } from 'luxon'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { Image } from 'shared/lib/models/Image'
import { Cloneable } from '../utils/Misc'

export class SharedEvent extends Cloneable<SharedEvent> {
    get hasDurationOfMultipleDays() {
        return (this.totalNumberOfDaysFromInitialStart ?? 0) > 0
    }

    constructor(
        readonly id: string,
        readonly start: DateTime,
        readonly groupId: string | undefined = undefined,
        readonly title: string | undefined = undefined,
        readonly end: DateTime | undefined = undefined,
        readonly isAllDay: boolean | undefined = undefined,
        readonly isExpired: boolean | undefined = undefined,
        readonly isDeleted: boolean | undefined = undefined,
        readonly totalNumberOfDaysFromInitialStart: number | undefined = undefined,
        readonly canMembersRegisterAttendance: boolean | undefined = undefined,
        readonly signedInUserAttendanceState: EventAttendanceState | undefined = undefined,
        readonly signedInUserImage: Image | undefined = undefined,
        readonly numberOfAttendees: number | undefined = undefined,
        readonly mostRecentAttendees: EventAttendance[] | undefined = undefined
    ) {
        super()
    }

    static fromResponse(response: any): SharedEvent {
        return new SharedEvent(
            response.id,
            DateTime.fromISO(response.start),
            response.groupId,
            response.title,
            response.end ? DateTime.fromISO(response.end) : undefined,
            response.isAllDay,
            response.isExpired,
            response.isDeleted,
            response.totalNumberOfDaysFromInitialStart,
            response.canMembersRegisterAttendance,
            response.signedInUserAttendanceState,
            response.signedInUserImage ? Image.fromResponse(response.signedInUserImage) : undefined,
            response.numberOfAttendees,
            response.mostRecentAttendees
                ? response.mostRecentAttendees.map((attendee: any) =>
                      EventAttendance.fromJSON(attendee)
                  )
                : undefined
        )
    }
}
