import { useCallback, useEffect, useRef, useState } from 'react'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { eventRepository } from '../../..'
import { EventAttendanceUpdatedEvent, EventEventKey } from '../../events'
import { handleEventAttendanceUpdatedEvent } from '../../EventUtil'

export const useAttendances = (eventId: string, eventStart: string) => {
    const abortControllerRef = useRef<AbortController>()

    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<Error | undefined>()
    const [attendances, setAttendances] = useState<EventAttendance[]>([])

    const getAttendancesIfNeeded = useCallback(() => {
        setIsLoading(true)

        eventRepository
            .getAttendances(eventId, eventStart, abortControllerRef.current?.signal)
            .then(setAttendances)
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [eventId, eventStart])

    const onEventAttendanceUpdated = useCallback(
        (ev: Event) =>
            setAttendances((attendances) =>
                handleEventAttendanceUpdatedEvent({
                    eventId,
                    eventStart,
                    event: ev as EventAttendanceUpdatedEvent,
                    attendances,
                })
            ),
        [eventId, eventStart]
    )

    useEffect(() => {
        document.addEventListener(EventEventKey.EVENT_ATTENDANCE_UPDATED, onEventAttendanceUpdated)

        return () => {
            document.removeEventListener(
                EventEventKey.EVENT_ATTENDANCE_UPDATED,
                onEventAttendanceUpdated
            )
        }
    }, [onEventAttendanceUpdated])

    useEffect(() => {
        getAttendancesIfNeeded()
    }, [getAttendancesIfNeeded])

    return {
        isLoading,
        error,
        attendances,
    }
}
