import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { DateTime } from 'luxon'
import { RecurrenceRule } from 'shared/lib/recurring/RecurrenceRule'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'

export class CreateEventBody {
    constructor(
        readonly title: string,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly recurrenceRule: RecurrenceRule | undefined,
        readonly description: string,
        readonly location: string,
        readonly groupId: string,
        readonly localPdfs: LocalPdf[] | undefined,
        readonly canMembersRegisterAttendance: boolean
    ) {}

    public toFormData() {
        const formData = new FormData()
        formData.append('title', this.title)
        formData.append('start', this.start.toString())
        formData.append('end', this.end.toString())
        formData.append('isAllDay', `${this.isAllDay}`)

        const recurrenceRuleString = RecurrenceRuleFactory.toRRUleString(this.recurrenceRule)
        if (recurrenceRuleString) {
            formData.append('recurrenceRule', recurrenceRuleString)
        }

        formData.append('canMembersRegisterAttendance', `${this.canMembersRegisterAttendance}`)

        formData.append('description', this.description)
        formData.append('location', this.location)
        formData.append('groupId', this.groupId)

        const localPdfs = this.localPdfs
        localPdfs?.forEach((pdf) => {
            formData.append('pdfFiles', pdf.file)
            formData.append('pdfImageFiles', pdf.thumbnail.file)
        })

        return formData
    }
}
