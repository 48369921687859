import { useCallback, useState } from 'react'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { useTranslation } from 'shared/lib/i18n'
import { Pdf } from 'shared/lib/models/Pdf'
import { EditOrDeleteEventDialog } from 'shared/lib/recurring/EditOrDeleteEventDialog'
import { EditOrDeleteRecurringEventOption } from 'shared/lib/recurring/EditOrDeleteRecurringEventOption'
import theme from 'shared/lib/theme/Theme'
import { eventRepository } from '../..'
import { Event } from '../Event'
import { CreateChildEventBody } from '../api/CreateChildEventBody'
import { SplitEventBody } from '../api/SplitEventBody'
import { UpdateEventBody } from '../api/UpdateEventBody'
import { EventPopup } from './EventPopup'
import { useCreateOrEditEventState } from './hooks'

interface Properties {
    event: Event
    onCloseButtonClicked: () => void
    onEventUpdated: (event: Event) => void
}

export const EditEventPopup = (properties: Properties) => {
    const translations = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | undefined>(undefined)
    const [isAlertDialogVisible, setIsAlertDialogVisible] = useState(false)
    const [isEditOptionsDialogVisible, setIsEditOptionsDialogVisible] = useState(false)

    const { event, hasChanges, canSaveChanges, onFieldChanged, getEditOptions } =
        useCreateOrEditEventState({
            event: properties.event,
        })

    const onPdfDeleted = useCallback(
        (pdfToDelete: Pdf | LocalPdf) => {
            if (pdfToDelete instanceof LocalPdf) {
                const updateSelectedPdfs = event.selectedPdfs.filter((pdf) => pdf !== pdfToDelete)

                onFieldChanged('selectedPdfs')(updateSelectedPdfs)
            } else {
                const updatedPdfs = event.pdfs.filter((pdf) => pdf !== pdfToDelete)

                onFieldChanged('pdfs')(updatedPdfs)
            }
        },
        [event.selectedPdfs, event.pdfs, onFieldChanged]
    )

    const onCloseButtonClicked = () => {
        if (hasChanges) {
            setIsAlertDialogVisible(true)
        } else {
            properties.onCloseButtonClicked()
        }
    }
    const onSaveButtonClicked = async () => {
        if (getEditOptions()?.length) {
            setIsEditOptionsDialogVisible(true)
            return
        }

        onConfirmedSaveButtonClicked(undefined)
    }

    const onConfirmedSaveButtonClicked = async (option?: EditOrDeleteRecurringEventOption) => {
        setIsLoading(true)

        try {
            let start = event.start
            if (option === EditOrDeleteRecurringEventOption.ALL_EVENTS) {
                start = properties.event.initialStart
                start.set({
                    hour: event.start.hour,
                    minute: event.start.minute,
                    second: event.start.second,
                })
            }

            let end = event.end
            if (
                option !== undefined &&
                [
                    EditOrDeleteRecurringEventOption.THIS_EVENT,
                    EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS,
                ].includes(option)
            ) {
                end = event.end
            } else if (properties.event.end === event.end) {
                end = properties.event.initialEnd
                end.set({
                    hour: event.end.hour,
                    minute: event.start.minute,
                    second: event.start.second,
                })
            }

            switch (option) {
                case EditOrDeleteRecurringEventOption.THIS_EVENT:
                    const childEventBody = new CreateChildEventBody(
                        event.title,
                        event.start,
                        event.end,
                        event.isAllDay,
                        event.description || '',
                        event.location || '',
                        event.pdfs,
                        Event.getDatesToExclude({
                            start: properties.event.start,
                            totalNumberOfDaysFromInitialStart:
                                properties.event.totalNumberOfDaysFromInitialStart,
                            numberOfDaysFromInitialStart:
                                properties.event.numberOfDaysFromInitialStart,
                        }),
                        event.selectedPdfs,
                        event.canMembersRegisterAttendance
                    )

                    properties.onEventUpdated(
                        await eventRepository.createChildEvent(childEventBody, properties.event.id)
                    )
                    break
                case EditOrDeleteRecurringEventOption.THIS_AND_FOLLOWING_EVENTS:
                    const splitEventBody = new SplitEventBody(
                        event.title,
                        event.start,
                        event.end,
                        event.isAllDay,
                        event.recurrenceRule,
                        event.description || '',
                        event.location || '',
                        event.pdfs,
                        event.start.minus({ days: properties.event.numberOfDaysFromInitialStart }),
                        event.selectedPdfs,
                        event.canMembersRegisterAttendance
                    )

                    properties.onEventUpdated(
                        await eventRepository.splitEvent(splitEventBody, properties.event.id)
                    )
                    break
                default:
                    const body = new UpdateEventBody(
                        event.title,
                        event.start,
                        event.end,
                        event.isAllDay,
                        event.recurrenceRule,
                        event.description || '',
                        event.location || '',
                        event.pdfs,
                        event.selectedPdfs,
                        event.canMembersRegisterAttendance
                    )

                    properties.onEventUpdated(
                        await eventRepository.updateEvent(properties.event.id, body)
                    )
                    break
            }
        } catch (error: any) {
            setIsLoading(false)
            setError(error.message)
        }
    }

    return (
        <>
            <EventPopup
                mode="editing"
                canSelectGroup={false}
                canContinue={hasChanges && canSaveChanges && !isLoading}
                canMakeRecurring={properties.event.canMakeRecurring}
                isLoading={isLoading}
                event={event}
                onFieldChanged={onFieldChanged}
                selectedGroup={properties.event.group}
                continueButtonTitle={translations('save')}
                onCloseButtonClicked={onCloseButtonClicked}
                onSaveButtonClicked={onSaveButtonClicked}
                onPdfDeleted={onPdfDeleted}
            />

            <AlertDialog
                isLoading={false}
                isVisible={isAlertDialogVisible}
                titleIcon={<AlertIcon fill={theme.palette.primary.main} />}
                title={translations('changes_are_not_saved')}
                message={translations('are_you_sure_concept_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setIsAlertDialogVisible(false)}
                continueButtonType={ButtonType.NONE}
                continueButtonTitle={translations('undo_changes')}
                onContinueButtonClicked={properties.onCloseButtonClicked}
            />

            <EditOrDeleteEventDialog
                type="edit"
                isVisible={isEditOptionsDialogVisible}
                options={getEditOptions() ?? []}
                translations={translations}
                onContinueButtonClicked={(option) => {
                    setIsEditOptionsDialogVisible(false)
                    onConfirmedSaveButtonClicked(option)
                }}
                onCancelButtonClicked={() => setIsEditOptionsDialogVisible(false)}
            />

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
