import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { GreyContainedButton } from 'shared/lib/components/buttons/GreyButtons'
import { Popup } from 'shared/lib/components/Popup'
import { useTranslation } from 'shared/lib/i18n'
import { grey_3 } from 'shared/lib/theme/constants'
import { fileRepository } from '..'
import { Event } from '../events/Event'
import { GroupAvatar } from '../groups/GroupAvatar'
import { PostSharedEventView } from '../mychurch/components/PostSharedEventView.tsx'
import { CreatePostPopup } from '../posts/createpostpopup/CreatePostPopup'
import { Post } from '../posts/Post'

interface Properties {
    event: Event
    onSkipButtonClicked: () => void
    onSuccessfullyShared: (post: Post) => void
}

export const PromotionPopup = ({ event, ...properties }: Properties) => {
    const translations = useTranslation()

    const [isCreatePostPopupVisible, setIsCreatePostPopupVisible] = useState<boolean>(false)

    const sharedEvent = event.toSharedEvent()
    const message = event.getPromotionMessage(translations)

    return (
        <>
            <Popup isVisible={true} withBackdrop={false}>
                <Container>
                    <ScrollContainer>
                        <TitleTextView variant="body1">
                            {translations('promote_your_event')}
                        </TitleTextView>

                        <ContentContainer>
                            <GroupContainer>
                                <GroupAvatar
                                    src={fileRepository.getThumbnailUrl(event.group.image)}
                                />
                                <Box>
                                    <Typography variant="body2">
                                        {translations('post_in')}
                                    </Typography>
                                    <Typography variant="body1" fontWeight="bold">
                                        {event.group.name}
                                    </Typography>
                                </Box>
                            </GroupContainer>

                            <Typography variant="body1" mt={2} mx={2} whiteSpace={'pre-line'}>
                                {message}
                            </Typography>

                            <PostSharedEventView isReadOnly={true} sharedEvent={sharedEvent} />
                        </ContentContainer>
                    </ScrollContainer>

                    <BottomContainer>
                        <GreyContainedButton
                            title={translations('skip')}
                            onClick={properties.onSkipButtonClicked}
                        />
                        <PrimaryContainedButton
                            title={translations('share_event')}
                            onClick={() => setIsCreatePostPopupVisible(true)}
                        />
                    </BottomContainer>
                </Container>
            </Popup>

            {isCreatePostPopupVisible && (
                <CreatePostPopup
                    withBackdrop={false}
                    selectedGroupId={event.group.id}
                    message={message}
                    sharedEvent={sharedEvent}
                    onCloseButtonClicked={() => setIsCreatePostPopupVisible(false)}
                    onSubmitButtonClicked={(post) => {
                        setIsCreatePostPopupVisible(false)
                        properties.onSuccessfullyShared(post)
                    }}
                />
            )}
        </>
    )
}

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '100%',
    backgroundColor: grey_3,
}))

const ScrollContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(4, 2, 14, 2),
}))

const TitleTextView = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: '33px',
}))

const ContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '24px',
    backgroundColor: 'white',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
}))

const GroupContainer = styled(Box)(({ theme }) => ({
    width: 'fit-content',
    display: 'flex',
    border: `1px solid ${grey_3}`,
    gap: theme.spacing(1),
    padding: theme.spacing(1, 3, 1, 1),
    margin: theme.spacing(2, 2, 0, 2),
    borderRadius: '28px',
}))

const BottomContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    left: 0,
    backgroundColor: 'white',
    borderTopLeftRadius: '24px',
    borderTopRightRadius: '24px',
    boxShadow: '0px -2px 16px 0px rgba(0, 0, 0, 0.08)',
    justifyContent: 'space-between',
    display: 'flex',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}))
