import { useCallback, useContext, useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { Pdf } from 'shared/lib/models/Pdf'
import { eventRepository } from '../..'
import { GroupsContext } from '../../groups/GroupsContextProvider'
import { useTranslation } from 'shared/lib/i18n'
import { EventPopup } from './EventPopup'
import { CreateEventBody } from '../api/CreateEventBody'
import { Event } from '../Event'
import { useCreateOrEditEventState } from './hooks'
import { PromotionPopup } from '../../promotion/PromotionPopup'
import { Post } from '../../posts/Post'
import { useNavigate } from 'react-router-dom'
import { Paths } from '../../routing/paths'
import { EventCreatedEvent } from '../events'

interface Properties {
    groupId?: string
    selectedPdfs: LocalPdf[]
    onCloseButtonClicked: () => void
    onEventCreated: (event: Event) => void
}

export const CreateEventPopup = ({ groupId, ...properties }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()

    const { groups } = useContext(GroupsContext)
    const { event, canSaveChanges, onFieldChanged } = useCreateOrEditEventState({
        selectedPdfs: properties.selectedPdfs,
    })

    const { title, description, start, end, isAllDay, location, pdfs } = event

    const [group, setGroup] = useState(groups.find((group) => group.id === groupId))
    const [eventToPromote, setEventToPromote] = useState<Event | undefined>()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error | undefined>()

    const navigateToGroupOnTimeline = (post: Post) => {
        navigate(Paths.GROUP_TIME_LINE.replace(':groupId', post.groupId), { replace: true })
    }

    const onSkipPromotionButtonClicked = () => {
        setEventToPromote(undefined)
        if (eventToPromote) {
            document.dispatchEvent(new EventCreatedEvent(eventToPromote))
            properties.onEventCreated(eventToPromote)
        }
    }

    const onPdfDeleted = useCallback(
        (pdfToDelete: Pdf | LocalPdf) => {
            if (pdfToDelete instanceof LocalPdf) {
                const updateSelectedPdfs = event.selectedPdfs.filter((pdf) => pdf !== pdfToDelete)

                onFieldChanged('selectedPdfs')(updateSelectedPdfs)
            } else {
                const updatedPdfs = pdfs.filter((pdf) => pdf !== pdfToDelete)
                onFieldChanged('pdfs')(updatedPdfs)
            }
        },
        [pdfs, event.selectedPdfs, onFieldChanged]
    )

    const onSaveButtonClicked = async (groupSelectorRef: HTMLDivElement) => {
        if (!group) {
            groupSelectorRef.click()
            return
        }

        setIsLoading(true)
        try {
            const body = new CreateEventBody(
                title,
                start,
                end,
                isAllDay,
                event.recurrenceRule,
                description,
                location,
                group.id,
                event.selectedPdfs,
                event.canMembersRegisterAttendance
            )

            const createdEvent = await eventRepository.createEvent(body)

            if (createdEvent.canMembersRegisterAttendance) {
                setEventToPromote(createdEvent)
                return
            }

            document.dispatchEvent(new EventCreatedEvent(createdEvent))
            properties.onEventCreated(createdEvent)
        } catch (error: any) {
            setIsLoading(false)
            setError(error.message)
        }
    }

    return (
        <>
            <EventPopup
                mode="creating"
                canSelectGroup={true}
                canContinue={canSaveChanges && !isLoading}
                canMakeRecurring={true}
                event={event}
                onFieldChanged={onFieldChanged}
                isLoading={isLoading}
                groups={groups}
                selectedGroup={group}
                continueButtonTitle={translations('save')}
                onCloseButtonClicked={properties.onCloseButtonClicked}
                onSaveButtonClicked={onSaveButtonClicked}
                onPdfDeleted={onPdfDeleted}
                onGroupSelected={setGroup}
            />

            {eventToPromote && (
                <PromotionPopup
                    event={eventToPromote}
                    onSkipButtonClicked={onSkipPromotionButtonClicked}
                    onSuccessfullyShared={(post) => navigateToGroupOnTimeline(post)}
                />
            )}

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
