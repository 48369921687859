import { Box, styled } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import { StyledVirtuoso } from '../../../common/StyledVirtuoso'
import { useDetailView } from '../../../common/detailView/hooks'
import { UserListItemRow, UserListLoadingRow } from '../../../user/userList/UserListItemRows'
import {
    AttendanceListItemViewModel,
    AttendanceListLoadingViewModel,
    AttendanceListViewModel,
} from './AttendanceListViewModels'
import { MinimalUserInfo } from 'shared/lib/users/MinimalUserInfo'

interface Properties {
    attendances: AttendanceListViewModel[]
}

export const AttendancesList = ({ attendances }: Properties) => {
    const { openUserDetailView } = useDetailView()

    const getRow = (index: number) => {
        const isLastItem = index === attendances.length - 1
        const viewModel = attendances[index]

        if (viewModel instanceof AttendanceListLoadingViewModel) {
            return <UserListLoadingRow />
        }

        const attendance = (viewModel as AttendanceListItemViewModel).attendance

        const user = {
            id: attendance.userId,
            firstName: attendance.firstName,
            lastName: attendance.lastName,
            image: attendance.image,
            functions: attendance.functions,
        } as MinimalUserInfo

        return (
            <UserListItemRow
                user={user}
                onClick={() => openUserDetailView(attendance.userId)}
                isClickable={true}
                isLastItem={isLastItem}
            />
        )
    }

    return (
        <Container>
            <AutoSizer>
                {({ height, width }) => (
                    <StyledVirtuoso
                        totalCount={attendances.length}
                        itemContent={getRow}
                        overscan={500}
                        style={{
                            height,
                            width,
                        }}
                    />
                )}
            </AutoSizer>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: `calc(100% - 80px)`,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.only('mobile')]: {
        paddingBottom: theme.spacing(1),
    },
}))
