import { useContext, useState } from 'react'
import { MAX_PDF_SIZE_BYTES, MAX_UPLOAD_SIZE_BYTES } from 'shared/lib/common/Constants'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { LocalFile } from 'shared/lib/forms/LocalFile'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { LocalVideo } from 'shared/lib/forms/LocalVideo'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { MediaItem } from 'shared/lib/models/MediaItem'
import { Group } from '../../groups/Group'
import { useTranslation } from 'shared/lib/i18n'
import { fileRepository, postRepository } from '../../index'
import { CreatePostBody } from '../CreatePostBody'
import { Post } from '../Post'
import { PostPopup } from './PostPopup'
import { GroupsContext } from '../../groups/GroupsContextProvider'
import { SharedEvent } from '../SharedEvent'

interface CreatePostPopupProperties {
    withBackdrop?: boolean
    selectedGroupId?: string
    message?: string
    localFiles?: LocalFile[]
    postToShare?: Post
    groupToShare?: Group
    sharedEvent?: SharedEvent
    onCloseButtonClicked: () => void
    onSubmitButtonClicked: (post: Post) => void
}

export const CreatePostPopup = (properties: CreatePostPopupProperties) => {
    const translations = useTranslation()

    const { getGroups } = useContext(GroupsContext)!

    const [message, setMessage] = useState(properties.message ?? '')
    const [localImagesAndVideos, setLocalImagesAndVideos] = useState<(LocalImage | LocalVideo)[]>(
        []
    )
    const [localPdfFile, setLocalPdfFile] = useState<LocalPdf | undefined>()
    const [linkPreview, setLinkPreview] = useState<LinkPreview | undefined>()
    const [postToShare, setPostToShare] = useState(properties.postToShare)
    const [groupToShare, setGroupToShare] = useState(properties.groupToShare)
    const [sharedEvent, setSharedEvent] = useState(properties.sharedEvent)
    const [hasDeletedLinkPreview, setHasDeletedLinkPreview] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | undefined>()

    const getTotalsSizeFromSelectedItems = (): number => {
        if (localPdfFile) {
            return localPdfFile.file.size
        }

        return localImagesAndVideos.reduce((count, localFile) => {
            return count + localFile.file.size
        }, 0)
    }

    const isSizeToBig = (): boolean => {
        return (
            getTotalsSizeFromSelectedItems() >
            (localPdfFile ? MAX_PDF_SIZE_BYTES : MAX_UPLOAD_SIZE_BYTES)
        )
    }

    const canContinue =
        ((message.length > 0 ||
            localImagesAndVideos.length > 0 ||
            localPdfFile ||
            postToShare ||
            groupToShare ||
            sharedEvent) &&
            !isSizeToBig()) === true

    const onMessageChanged = async (message: string) => {
        if (message === '') {
            setHasDeletedLinkPreview(false)
        }

        setMessage(message)
    }

    const onMediaItemsSelected = (images: LocalImage[], videos: LocalVideo[]) => {
        const existingVideos = LocalFile.filterOnType(localImagesAndVideos, LocalVideo)
        const existingImages = LocalFile.filterOnType(localImagesAndVideos, LocalImage)

        setLocalImagesAndVideos([...existingVideos, ...videos, ...existingImages, ...images])
    }

    const onPdfSelected = async (localPdfs: LocalPdf[]) => {
        setLocalPdfFile(localPdfs[0])
    }

    const onDeleteButtonLinkPreviewClicked = () => {
        setLinkPreview(undefined)
        setHasDeletedLinkPreview(true)
    }

    const onDeleteButtonImageOrVideoClicked = (mediaItem: MediaItem) => {
        setLocalImagesAndVideos(localImagesAndVideos.filter((file) => file.id !== mediaItem.id))
    }

    const onDeleteButtonPdfClicked = () => {
        setLocalPdfFile(undefined)
    }

    const onContinueButtonClicked = async (group: Group) => {
        setIsLoading(true)

        try {
            const post = await postRepository.createPost(
                new CreatePostBody(
                    group.id,
                    message,
                    localImagesAndVideos,
                    linkPreview,
                    localPdfFile,
                    postToShare?.id,
                    groupToShare?.id,
                    sharedEvent
                )
            )
            await getGroups()

            properties.onSubmitButtonClicked(post)
        } catch (error: any) {
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <PostPopup
                isLoading={isLoading}
                isGroupSelectorEnabled={true}
                isSizeToBig={isSizeToBig()}
                canContinue={canContinue}
                hasDeletedLinkPreview={hasDeletedLinkPreview}
                withBackdrop={properties.withBackdrop}
                selectedGroupId={properties.selectedGroupId}
                localFiles={properties.localFiles}
                message={message}
                continueButtonText={translations('place_post')}
                sharedPostId={postToShare?.id}
                sharedGroupId={groupToShare?.id}
                sizeFromContainingItems={getTotalsSizeFromSelectedItems()}
                linkPreview={linkPreview}
                pdfFile={localPdfFile}
                mediaItems={MediaItem.mapToMediaItems(
                    {
                        localImages: LocalFile.filterOnType(localImagesAndVideos, LocalImage),
                        localVideos: LocalFile.filterOnType(localImagesAndVideos, LocalVideo),
                    },
                    fileRepository
                )}
                sharedPost={postToShare}
                sharedGroup={groupToShare}
                sharedEvent={sharedEvent}
                onMessageChanged={onMessageChanged}
                onLinkPreviewUpdated={setLinkPreview}
                onDeleteButtonLinkPreviewClicked={onDeleteButtonLinkPreviewClicked}
                onMediaItemsSelected={onMediaItemsSelected}
                onDeleteImageOrVideoClicked={onDeleteButtonImageOrVideoClicked}
                onPdfSelected={onPdfSelected}
                onDeleteButtonPdfClicked={onDeleteButtonPdfClicked}
                onDeleteSharedPostClicked={() => setPostToShare(undefined)}
                onDeleteSharedGroupClicked={() => setGroupToShare(undefined)}
                onDeleteSharedEventClicked={() => setSharedEvent(undefined)}
                onCloseButtonClicked={properties.onCloseButtonClicked}
                onContinueButtonClicked={onContinueButtonClicked}
            />

            <ErrorHandler error={error} translations={translations} />
        </>
    )
}
