import { Badge as MuiBadge, badgeClasses, BadgeProps, styled } from '@mui/material'
import { fileRepository } from '../../../../index'
import React, { memo, useContext } from 'react'
import { Avatar as StyledAvatar } from 'shared/lib/components/Avatar'
import { NotificationType } from '../../../NotificationType'
import { ReactComponent as MessageIcon } from '../../../../assets/svg/message_filled.svg'
import { ReactComponent as ReplyIcon } from '../../../../assets/svg/reply_filled.svg'
import { LikeIcon } from '../../../../posts/likes/LikeIcon'
import { ReactComponent as GroupIcon } from '../../../../assets/svg/group.svg'
import { ReactComponent as ApprovedIcon } from 'shared/lib/assets/svg/check.svg'
import { CalendarIconFilled } from 'shared/lib/assets/svg/CalendarIconFilled'
import { GroupAvatar } from '../../../../groups/GroupAvatar'
import { Notification } from '../../../Notification'
import { paperColor } from 'shared/lib/theme/Theme'
import ChurchContext from '../../../../churches/ChurchContextProvider'
import { AtIcon } from 'shared/lib/assets/svg/AtIcon'

interface Properties {
    notification: Notification
}

export const NotificationAvatar = memo(
    ({ notification }: Properties) => {
        return notification.type === NotificationType.POST_LIKED ? (
            <Badge notification={notification} />
        ) : (
            <StyledBadge notification={notification} />
        )
    },
    (prevProps, nextProps) => {
        return prevProps.notification.id === nextProps.notification.id
    }
)

type BadgeProperties = Omit<BadgeProps, 'overlap' | 'anchorPosition' | 'badgeContent'> & Properties

const Badge = styled(({ notification, ...props }: BadgeProperties) => {
    const { church } = useContext(ChurchContext)!

    const BadgeContent = () => {
        if (notification.isUserTagged) {
            return <AtIcon />
        }

        switch (notification.type) {
            case NotificationType.EVENT_ATTENDANCE_UPDATED:
                return <CalendarIconFilled />
            case NotificationType.POST_PLACED_IN_GROUP:
                return <MessageIcon />
            case NotificationType.COMMENT_ON_POST:
                return <ReplyIcon />
            case NotificationType.POST_LIKED:
                return <LikeIcon likeType={notification.likeType} />
            case NotificationType.USER_REQUESTED_ACCESS_TO_GROUP:
            case NotificationType.USER_ADDED_TO_GROUP:
            case NotificationType.USER_ACCESS_DENIED_TO_GROUP:
            case NotificationType.USER_ACCESS_GRANTED_TO_GROUP:
                return <GroupIcon />
            case NotificationType.USER_APPROVED:
                return <ApprovedIcon />
            default:
                return null
        }
    }

    const Avatar = () => {
        switch (notification.type) {
            case NotificationType.USER_REQUESTED_ACCESS_TO_GROUP:
            case NotificationType.USER_ADDED_TO_GROUP:
            case NotificationType.USER_ACCESS_DENIED_TO_GROUP:
            case NotificationType.USER_ACCESS_GRANTED_TO_GROUP:
                return (
                    <GroupAvatar src={fileRepository.getThumbnailUrl(notification.group!.image)} />
                )
            case NotificationType.USER_APPROVED:
                return <GroupAvatar src={fileRepository.getThumbnailUrl(church?.image)} />
            default:
                return (
                    <StyledAvatar
                        src={fileRepository.getThumbnailUrl(notification.sender?.image)}
                    />
                )
        }
    }

    return (
        <MuiBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<BadgeContent />}
            {...props}
        >
            <Avatar />
        </MuiBadge>
    )
})(() => ({
    [`& .${badgeClasses.badge}`]: {
        width: 30,
        height: 30,
        borderRadius: 15,
        border: '2px solid white',
        padding: 0,
        zIndex: 0,
    },

    '& svg': {
        fill: paperColor,
        width: 30,
        height: 30,
    },
}))

const StyledBadge = styled((props: BadgeProperties) => <Badge {...props} />)(({ theme }) => ({
    [`& .${badgeClasses.badge}`]: {
        backgroundColor: theme.palette.primary.main,
        padding: 6,
    },
}))
