import { Pdf } from 'shared/lib/models/Pdf'
import { LocalPdf } from 'shared/lib/forms/LocalPdf'
import { DateTime } from 'luxon'
import { RecurrenceRule } from 'shared/lib/recurring/RecurrenceRule'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'

export class UpdateEventBody {
    constructor(
        readonly title: string,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly recurrenceRule: RecurrenceRule | undefined,
        readonly description: string,
        readonly location: string,
        readonly pdfs: Pdf[],
        readonly localPdfs: LocalPdf[] | undefined,
        readonly canMembersRegisterAttendance: boolean
    ) {}

    public toFormData() {
        const formData = new FormData()
        const pdfs = this.pdfs
        const localPdfs = this.localPdfs
        formData.append('title', this.title)
        formData.append('start', this.start.toString())
        formData.append('end', this.end.toString())
        formData.append('isAllDay', `${this.isAllDay}`)

        const recurrenceRuleString = RecurrenceRuleFactory.toRRUleString(this.recurrenceRule)
        if (recurrenceRuleString) {
            formData.append('recurrenceRule', recurrenceRuleString)
        } else {
            formData.append('recurrenceRule', 'null')
        }

        formData.append('canMembersRegisterAttendance', `${this.canMembersRegisterAttendance}`)
        formData.append('description', this.description)
        formData.append('location', this.location)
        localPdfs?.forEach((pdf) => {
            formData.append('pdfFiles', pdf.file)
            formData.append('pdfImageFiles', pdf.thumbnail.file)
        })
        if (pdfs.length === 0) {
            formData.append('pdfs[]', '')
        } else {
            pdfs.forEach((pdf) =>
                formData.append(
                    'pdfs[]{}',
                    JSON.stringify({
                        name: pdf.name,
                        key: pdf.key,
                        imageKey: pdf.imageKey,
                        size: pdf.size,
                    })
                )
            )
        }
        return formData
    }
}
