'use client'

import { Box, Link, LinkProps, styled } from '@mui/material'
import { ForwardedRef, forwardRef, useCallback, useEffect, useState } from 'react'
import { MessageLinkifyView } from './MessageLinkifyView'
import { MessageMarkdownView } from './MessageMarkdownView'
import { paperColor } from '../../theme/Theme'
import { capitalizeFirstLetter } from '../../utils/StringUtils'
import { MessageHtmlView } from './MessageHtmlView'

interface MessageDetails {
    id: string
    hasMarkDown?: boolean
    hasHtml?: boolean
    message: string
}

interface Properties {
    canCollapse?: boolean
    details: MessageDetails
    mx?: number
    mt?: number
    backgroundColor?: string
    linesToShowWhenCollapsed?: number
    translations: {
        readMore: string
        readLess: string
    }

    onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void
}

export const MessageView = ({ translations, details, ...properties }: Properties) => {
    const [isTruncated, setIsTruncated] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(true)

    const className = `${details.id}-message-element`
    const message = details.message
    const hasMarkdown = details.hasMarkDown || false
    const hasHtml = details.hasHtml || false

    const onResize = useCallback(() => {
        const element = document.getElementsByClassName(className).item(0) as HTMLElement | null

        if (!element) {
            return
        }

        const hasOverflow = element.offsetHeight < element.scrollHeight

        setIsTruncated(hasOverflow)
        setIsCollapsed(true)
    }, [className])

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [onResize])

    useEffect(() => {
        if (message) {
            onResize()
        }
    }, [message, onResize])

    if (!message) {
        return <></>
    }

    return (
        <Box
            position="relative"
            px={properties.mx}
            pt={properties.mt}
            width="100%"
            onClick={properties.onClick}
            sx={{
                '& p': {
                    fontSize: '14px',
                },
            }}
        >
            {hasMarkdown && (
                <MessageMarkdownView
                    isCollapsed={isCollapsed}
                    className={className}
                    message={message}
                    translations={translations}
                    linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
                    onCollapseClicked={() => setIsCollapsed(true)}
                />
            )}
            {hasHtml && (
                <MessageHtmlView
                    isCollapsed={isCollapsed}
                    className={className}
                    message={message}
                    translations={translations}
                    linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
                    onCollapseClicked={() => setIsCollapsed(true)}
                />
            )}
            {!hasMarkdown && !hasHtml && (
                <MessageLinkifyView
                    isCollapsed={isCollapsed}
                    className={className}
                    message={message}
                    linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
                    translations={translations}
                    onCollapseClicked={() => setIsCollapsed(true)}
                />
            )}
            {isTruncated && isCollapsed && (
                <ReadMoreButton
                    right={properties.mx}
                    component="button"
                    onClick={() => setIsCollapsed(false)}
                    backgroundColor={properties.backgroundColor}
                >
                    {capitalizeFirstLetter(translations.readMore.replace('… ', ''))}
                </ReadMoreButton>
            )}
        </Box>
    )
}

const ReadMoreButton = styled(
    forwardRef(
        (
            {
                backgroundColor,
                right: string,
                ...props
            }: LinkProps & { component: string; backgroundColor?: string; right?: number },
            ref: ForwardedRef<HTMLAnchorElement>
        ) => <Link {...props} ref={ref} />
    )
)(({ theme, backgroundColor, right }) => ({
    position: 'absolute',
    right: right ? theme.spacing(right) : 0,
    bottom: 0,
    background: `linear-gradient(270deg, ${
        backgroundColor ?? paperColor
    } 91.18%, rgba(255, 255, 255, 0.00) 100%)`,
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
}))
