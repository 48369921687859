import { DateTime } from 'luxon'
import { useCallback, useEffect, useRef, useState } from 'react'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { eventRepository } from '../..'
import { GroupEventKey, GroupUpdatedEvent } from '../../groups/events'
import { Event as DonkeyEvent } from '../Event'
import { EventAttendanceUpdatedEvent, EventEventKey } from '../events'
import { handleEventAttendanceUpdatedEvent } from '../EventUtil'

export const useEvent = (eventId: string, eventStart: string) => {
    const abortControllerRef = useRef<AbortController>()

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingAttendances, setIsLoadingAttendances] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [event, setEvent] = useState<DonkeyEvent | undefined>()
    const [attendances, setAttendances] = useState<EventAttendance[]>([])

    const determineStart = useCallback((event: DonkeyEvent | undefined) => {
        if (!event) return undefined

        if (event.hasDurationOfMultipleDays && !event.isRecurring) {
            return event.initialStart
        }

        const initialStart = event.initialStart
        if (event.hasDurationOfMultipleDays && event.isRecurring) {
            return event.end.minus({ days: event.totalNumberOfDaysFromInitialStart }).set({
                hour: initialStart.hour,
                minute: initialStart.minute,
                second: initialStart.second,
            })
        }

        return event.start
    }, [])

    const getEvent = useCallback(() => {
        setIsLoading(true)

        const start = DateTime.fromISO(eventStart).toJSDate().toISOString()
        eventRepository
            .getEvent(eventId, start, abortControllerRef.current?.signal)
            .then((event) => {
                setEvent(event)
                getAttendancesIfNeeded(event)
            })
            .catch(setError)
            .finally(() => setIsLoading(false))
    }, [eventId, eventStart]) // eslint-disable-line react-hooks/exhaustive-deps

    const getAttendancesIfNeeded = useCallback(
        (event: DonkeyEvent) => {
            const eventStart = determineStart(event)?.toJSDate().toISOString()

            if (!eventStart) {
                return
            }

            setIsLoadingAttendances(true)

            eventRepository
                .getAttendances(event.id, eventStart, abortControllerRef.current?.signal)
                .then(setAttendances)
                .catch(setError)
                .finally(() => setIsLoadingAttendances(false))
        },
        [determineStart]
    )

    const onGroupUpdated = useCallback(
        (ev: Event) => {
            const { id, name } = (ev as GroupUpdatedEvent).detail

            if (event && event?.groupId === id) {
                setEvent(
                    event.cloneWith({
                        group: {
                            ...event.group,
                            name,
                        },
                    })
                )
            }
        },
        [event]
    )

    const onEventAttendanceUpdated = useCallback(
        (ev: Event) =>
            setAttendances((attendances) =>
                handleEventAttendanceUpdatedEvent({
                    eventId,
                    eventStart: determineStart(event)!.toJSDate().toISOString(),
                    event: ev as EventAttendanceUpdatedEvent,
                    attendances,
                })
            ),
        [eventId, event, determineStart]
    )

    useEffect(() => {
        abortControllerRef.current?.abort()
        abortControllerRef.current = new AbortController()

        getEvent()

        return () => {
            abortControllerRef.current?.abort()
        }
    }, [getEvent])

    useEffect(() => {
        document.addEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
        document.addEventListener(EventEventKey.EVENT_ATTENDANCE_UPDATED, onEventAttendanceUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_UPDATED, onGroupUpdated)
            document.removeEventListener(
                EventEventKey.EVENT_ATTENDANCE_UPDATED,
                onEventAttendanceUpdated
            )
        }
    }, [onGroupUpdated, onEventAttendanceUpdated])

    return {
        isLoading,
        isLoadingAttendances,
        error,
        event,
        start: determineStart(event),
        attendances,
        getEvent,
        setEvent,
    }
}
