import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { ActivityIndicator } from 'shared/lib/components/ActivityIndicator'
import { EventAttendance } from 'shared/lib/events/EventAttendance'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { useTranslation } from 'shared/lib/i18n'
import { Image } from 'shared/lib/models/Image'
import { defaultTransition, grey_3 } from 'shared/lib/theme/constants'
import { fileRepository } from '../..'

interface Properties {
    isReadOnly?: boolean
    isLoading?: boolean
    hasWhiteBackground?: boolean
    showAttendeesButtonFullWidth?: boolean
    signedInUserState?: EventAttendanceState
    signedInUserImage?: Image
    attendeesWithoutSignedInUser?: EventAttendance[]
    numberOfAttendees?: number
    onButtonClicked(): void
}

export const EventAttendeesView = ({
    signedInUserState,
    signedInUserImage,
    ...properties
}: Properties) => {
    const translations = useTranslation()

    const isReadOnly = properties.isReadOnly ?? false
    const isLoading = properties.isLoading ?? false
    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const showAttendeesButtonFullWidth = properties.showAttendeesButtonFullWidth ?? false
    const attendees = properties.attendeesWithoutSignedInUser ?? []
    const numberOfAttendees = properties.numberOfAttendees ?? 0

    const isAttending = signedInUserState === EventAttendanceState.ATTENDING

    const firstThumbnailUrl = fileRepository.getThumbnailUrl(attendees[0]?.image)
    const secondThumbnailUrl = fileRepository.getThumbnailUrl(attendees[1]?.image)
    const thirdThumbnailUrl = fileRepository.getThumbnailUrl(attendees[2]?.image)
    const fourthThumbnailUrl = fileRepository.getThumbnailUrl(attendees[3]?.image)

    const onButtonClicked = (ev: React.MouseEvent) => {
        ev.stopPropagation()
        properties.onButtonClicked()
    }

    return (
        <Container>
            <AvatarContainer
                isReadOnly={isReadOnly}
                isVisible={isAttending && !isLoading}
                hasWhiteBackground={hasWhiteBackground}
                marginLeft="-4px"
                onClick={onButtonClicked}
            >
                <Avatar src={fileRepository.getThumbnailUrl(signedInUserImage)} />
            </AvatarContainer>

            <AvatarContainer
                isReadOnly={isReadOnly}
                isVisible={!!firstThumbnailUrl && !isLoading}
                hasWhiteBackground={hasWhiteBackground}
                marginLeft={isAttending ? '-20px' : '-4px'}
                onClick={onButtonClicked}
            >
                <Avatar src={firstThumbnailUrl} />
            </AvatarContainer>

            <AvatarContainer
                isReadOnly={isReadOnly}
                isVisible={!!secondThumbnailUrl && !isLoading}
                hasWhiteBackground={hasWhiteBackground}
                marginLeft="-20px"
                onClick={onButtonClicked}
            >
                <Avatar src={secondThumbnailUrl} />
            </AvatarContainer>

            <AvatarContainer
                isReadOnly={isReadOnly}
                isVisible={!!thirdThumbnailUrl && !isLoading}
                hasWhiteBackground={hasWhiteBackground}
                marginLeft="-20px"
                onClick={onButtonClicked}
            >
                <Avatar src={thirdThumbnailUrl} />
            </AvatarContainer>

            <AvatarContainer
                isReadOnly={isReadOnly}
                isVisible={!isAttending && !!fourthThumbnailUrl && !isLoading}
                hasWhiteBackground={hasWhiteBackground}
                marginLeft="-20px"
                onClick={onButtonClicked}
            >
                <Avatar src={fourthThumbnailUrl} />
            </AvatarContainer>

            <NumberOfAttendeesContainer
                hasWhiteBackground={hasWhiteBackground}
                fullWidth={showAttendeesButtonFullWidth}
                marginLeft={numberOfAttendees > 0 ? '-20px' : '-4px'}
            >
                <NumberOfAttendeesButton
                    isReadOnly={isReadOnly}
                    hasWhiteBackground={hasWhiteBackground}
                    fullWidth={showAttendeesButtonFullWidth}
                    onClick={onButtonClicked}
                >
                    <NumberOfAttendeesTextView isLoading={isLoading}>
                        {translations('plural_attendees', [numberOfAttendees], numberOfAttendees)}
                    </NumberOfAttendeesTextView>

                    {isLoading && (
                        <Box position="absolute">
                            <ActivityIndicator size={36} />{' '}
                        </Box>
                    )}
                </NumberOfAttendeesButton>
            </NumberOfAttendeesContainer>
        </Container>
    )
}

const Container = styled(Box)(() => ({
    height: '56px',
    display: 'flex',
}))

const AvatarContainer = styled(
    ({
        isReadOnly,
        isVisible,
        hasWhiteBackground,
        marginLeft,
        ...properties
    }: BoxProps & { isReadOnly: boolean; isVisible: boolean; hasWhiteBackground: boolean }) => (
        <Box {...properties} />
    )
)(({ isReadOnly, isVisible, hasWhiteBackground, marginLeft }) => ({
    height: '56px',
    width: isVisible ? '56px' : '0px',
    marginLeft: isVisible ? (marginLeft as string) : '0px',
    borderRadius: '50%',
    backgroundColor: hasWhiteBackground ? grey_3 : 'white',
    transition: defaultTransition,
    cursor: 'pointer',
    pointerEvents: isReadOnly ? 'none' : 'auto',
}))

const Avatar = styled('img')(() => ({
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    margin: '4px',
    cursor: 'pointer',
    objectFit: 'cover',
}))

const NumberOfAttendeesContainer = styled(
    ({
        hasWhiteBackground,
        fullWidth,
        ...properties
    }: BoxProps & { hasWhiteBackground: boolean; fullWidth: boolean }) => <Box {...properties} />
)(({ hasWhiteBackground, fullWidth }) => ({
    height: '56px',
    width: fullWidth ? '100%' : undefined,
    backgroundColor: hasWhiteBackground ? grey_3 : 'white',
    borderRadius: '35px',
    overflow: 'hidden',
    transition: defaultTransition,
}))

const NumberOfAttendeesButton = styled(
    ({
        isReadOnly,
        hasWhiteBackground,
        fullWidth,
        ...properties
    }: BoxProps & { isReadOnly: boolean; hasWhiteBackground: boolean; fullWidth: boolean }) => (
        <Box {...properties} />
    )
)(({ isReadOnly, hasWhiteBackground, fullWidth, theme }) => ({
    position: 'relative',
    display: 'flex',
    height: '48px',
    width: fullWidth ? '100%' : undefined,
    backgroundColor: hasWhiteBackground ? 'white' : grey_3,
    borderRadius: '35px',
    margin: '4px',
    padding: theme.spacing(0, 2),
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    cursor: 'pointer',
    pointerEvents: isReadOnly ? 'none' : 'auto',
}))

const NumberOfAttendeesTextView = styled(
    ({ isLoading, ...properties }: TypographyProps & { isLoading: boolean }) => (
        <Typography variant="body1" {...properties} />
    )
)(({ isLoading }) => ({
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineBreak: 'anywhere',
    fontWeight: 'bold',
    opacity: isLoading ? 0 : 1,
}))
