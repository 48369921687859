import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'
import { useTranslation } from 'shared/lib/i18n'
import { defaultTransition, grey_2, grey_3, textColor } from 'shared/lib/theme/constants'
import { ReactComponent as AttendIcon } from '../../assets/svg/attend.svg'
import { ReactComponent as AttendingIcon } from '../../assets/svg/attending.svg'
import { ReactComponent as MaybeIcon } from '../../assets/svg/maybe_attending.svg'
import { ReactComponent as AbsentIcon } from '../../assets/svg/not_attending.svg'

interface Properties {
    isReadOnly?: boolean
    hasWhiteBackground?: boolean
    state?: EventAttendanceState

    onStateChanged?(state: EventAttendanceState): void
}

export const EventAttendanceView = (properties: Properties) => {
    const translations = useTranslation()

    const isReadOnly = properties.isReadOnly ?? false
    const hasWhiteBackground = properties.hasWhiteBackground ?? false

    const state = isReadOnly
        ? EventAttendanceState.NOT_RESPONDED
        : properties.state ?? EventAttendanceState.NOT_RESPONDED

    const isMaybeAttending = state === EventAttendanceState.MAYBE
    const isAbsent = state === EventAttendanceState.ABSENT
    const isAttending = state === EventAttendanceState.ATTENDING
    const hasNotResponsed = state === EventAttendanceState.NOT_RESPONDED

    const onStateChanged = (newState: EventAttendanceState) => {
        const updatedState = state === newState ? EventAttendanceState.NOT_RESPONDED : newState
        properties.onStateChanged?.(updatedState)
    }

    return (
        <Container>
            <ButtonContainer
                isReadOnly={isReadOnly}
                isSelected={isMaybeAttending}
                hasWhiteBackground={hasWhiteBackground}
                onClick={(event) => {
                    event.stopPropagation()
                    onStateChanged(EventAttendanceState.MAYBE)
                }}
            >
                {!isMaybeAttending && (
                    <ButtonImage>
                        <MaybeIcon stroke={isReadOnly ? 'white' : textColor} />
                    </ButtonImage>
                )}
                {isMaybeAttending && <ButtonTitle>{translations('maybe')}</ButtonTitle>}
            </ButtonContainer>

            <ButtonContainer
                isReadOnly={isReadOnly}
                isSelected={isAbsent}
                hasWhiteBackground={hasWhiteBackground}
                onClick={(event) => {
                    event.stopPropagation()
                    onStateChanged(EventAttendanceState.ABSENT)
                }}
            >
                {!isAbsent && (
                    <ButtonImage>
                        <AbsentIcon
                            fill={isReadOnly ? 'white' : textColor}
                            stroke={isReadOnly ? 'white' : textColor}
                        />
                    </ButtonImage>
                )}
                {isAbsent && <ButtonTitle>{translations('absent')}</ButtonTitle>}
            </ButtonContainer>

            <ButtonContainer
                isReadOnly={isReadOnly}
                isSelected={isAttending ? true : hasNotResponsed ? undefined : false}
                hasWhiteBackground={hasWhiteBackground}
                onClick={(event) => {
                    event.stopPropagation()
                    onStateChanged(EventAttendanceState.ATTENDING)
                }}
            >
                {isAttending && (
                    <AttendingIconContainer>
                        <AttendingIcon />
                    </AttendingIconContainer>
                )}
                {(isMaybeAttending || isAbsent) && (
                    <ButtonImage>
                        <AttendIcon />
                    </ButtonImage>
                )}
                {(hasNotResponsed || isAttending) && (
                    <ButtonTitle isAttending={isAttending}>
                        {translations(isAttending ? 'attending' : 'attend')}
                    </ButtonTitle>
                )}
            </ButtonContainer>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
}))

const ButtonContainer = styled(
    ({
        isReadOnly,
        isSelected,
        hasWhiteBackground,
        ...properties
    }: BoxProps & {
        isReadOnly: boolean
        isSelected: boolean | undefined
        hasWhiteBackground: boolean
    }) => <Box {...properties} />
)(({ isReadOnly, isSelected, hasWhiteBackground, theme }) => ({
    display: 'flex',
    height: '48px',
    minWidth: isSelected || isSelected === undefined ? undefined : '48px',
    backgroundColor: isReadOnly
        ? grey_2
        : isSelected
          ? theme.palette.primary.main
          : isSelected === undefined
            ? '#445058'
            : hasWhiteBackground
              ? 'white'
              : grey_3,
    borderRadius: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingLeft: theme.spacing(isSelected ? 2 : 0),
    paddingRight: theme.spacing(isSelected ? 2 : 0),
    flexGrow: isSelected || isSelected === undefined ? 1 : 0,
    transition: defaultTransition,
    overflow: 'hidden',
    cursor: 'pointer',
    pointerEvents: isReadOnly ? 'none' : 'auto',
}))

const ButtonImage = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '48px',
    height: '48px',
    borderRadius: '24px',
}))

const ButtonTitle = styled(
    ({ isAttending, ...properties }: TypographyProps & { isAttending?: boolean }) => (
        <Typography variant="body1" {...properties} />
    )
)(({ isAttending, theme }) => ({
    flexGrow: 1,
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    paddingRight: isAttending ? theme.spacing(3) : 0,

    [theme.breakpoints.down('desktop')]: {
        [`@media (max-width:880px)`]: {
            paddingLeft: isAttending ? theme.spacing(3) : 0,
        },
    },

    [`@media (max-width:400px)`]: {
        paddingLeft: isAttending ? theme.spacing(3) : 0,
    },
}))

const AttendingIconContainer = styled(Box)(({ theme }) => ({
    minWidth: '24px',
    height: '24px',

    [theme.breakpoints.down('desktop')]: {
        [`@media (max-width:880px)`]: {
            display: 'none',
        },
    },

    [`@media (max-width:400px)`]: {
        display: 'none',
    },
}))
