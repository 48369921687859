import { Post } from '../posts/Post'
import { LikeType } from '../posts/likes/LikeType'
import { Group } from '../groups/Group'
import { Cloneable } from '../utils/Misc'
import { EventAttendanceState } from 'shared/lib/events/EventAttendanceState'

export interface PostViewModel {}

export class PostItemViewModel extends Cloneable<PostItemViewModel> implements PostViewModel {
    constructor(
        readonly isGroupNameVisible: boolean,
        readonly isCreatingComment: boolean,
        readonly post: Post,
        readonly onEventAttendButtonClicked: (post: Post, state: EventAttendanceState) => void,
        readonly onLikeButtonClicked: (post: Post, like?: LikeType) => void,
        readonly onCreateCommentButtonClicked: (post: Post, message: string) => void,
        readonly onPostShared: () => void,
        readonly onEditPostButtonClicked: (post: Post) => void,
        readonly onDeletePostButtonClicked: (postId: string) => void
    ) {
        super()
    }
}

export class CreatePostViewModel implements PostViewModel {
    constructor(
        readonly groupId: string | undefined,
        readonly onPostCreated: () => void
    ) {}
}

export class PostLoadingViewModel implements PostViewModel {}

export class GroupHeaderViewModel implements PostViewModel {
    constructor(public readonly group: Pick<Group, 'id' | 'name'>) {}
}

export class CreatePostLoadingViewModel implements PostViewModel {}

export class LoadPostsErrorViewModel implements PostViewModel {}
