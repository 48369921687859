import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { grey_3 } from '../../theme/constants'

interface Properties {
    isSelected: boolean
    title: string
    count: number
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

export const ButtonWithCount = ({ isSelected, ...properties }: Properties) => {
    return (
        <Container onClick={properties.onClick} isSelected={isSelected}>
            <TitleTextView isSelected={isSelected}>{properties.title}</TitleTextView>
            <BadgeView isSelected={isSelected}>{properties.count}</BadgeView>
        </Container>
    )
}

const Container = styled(({ isSelected, ...properties }: BoxProps & { isSelected: boolean }) => (
    <Box {...properties} />
))(({ isSelected, theme }) => ({
    display: 'flex',
    height: '48px',
    borderRadius: '24px',
    backgroundColor: isSelected ? `${theme.palette.primary.main}40` : grey_3,
    alignItems: 'center',
    cursor: 'pointer',
}))

const TitleTextView = styled(
    ({ isSelected, ...properties }: TypographyProps & { isSelected: boolean }) => (
        <Typography variant="body1" {...properties} />
    )
)(({ isSelected, theme }) => ({
    marginLeft: theme.spacing(2),
    color: isSelected ? theme.palette.primary.main : theme.palette.text.primary,
    fontWeight: 'bold',
}))

const BadgeView = styled(({ isSelected, ...properties }: BoxProps & { isSelected: boolean }) => (
    <Box {...properties} />
))(({ isSelected, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '36px',
    height: '36px',
    borderRadius: '50%',
    backgroundColor: isSelected ? theme.palette.primary.main : 'white',
    color: isSelected ? 'white' : theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: '16px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
}))
