import { DateTime } from 'luxon'
import { Pdf } from 'shared/lib/models/Pdf'
import { MinimalGroupInfo } from '../groups/Group'
import { Cloneable } from '../utils/Misc'
import { RecurrenceRuleFactory } from 'shared/lib/recurring/RecurrenceRuleFactory'
import { range } from 'shared/lib/utils/ArrayUtil'
import { SharedEvent } from '../posts/SharedEvent'
import { TranslationFn } from 'shared/lib/WithTranslations'
import { stripMarkdown } from 'shared/lib/utils/StringUtils'

export class Event extends Cloneable<Event> {
    get isSameDay() {
        return this.start.hasSame(this.end, 'day')
    }

    get hasDurationOfMultipleDays() {
        return this.totalNumberOfDaysFromInitialStart > 0
    }

    get groupId() {
        return this.group.id
    }

    get rrule() {
        return RecurrenceRuleFactory.fromRRuleString(this.start, this.recurrenceRule)
    }

    get isRecurring() {
        return this.rrule !== undefined
    }

    get excludesStartDates(): DateTime[] {
        return Event.getDatesToExclude({
            start: this.start,
            totalNumberOfDaysFromInitialStart: this.totalNumberOfDaysFromInitialStart,
            numberOfDaysFromInitialStart: this.numberOfDaysFromInitialStart,
        })
    }

    constructor(
        readonly id: string,
        readonly parentId: string | undefined,
        readonly title: string,
        readonly group: MinimalGroupInfo & { isHome: boolean },
        readonly initialStart: DateTime,
        readonly initialEnd: DateTime,
        readonly start: DateTime,
        readonly end: DateTime,
        readonly isAllDay: boolean,
        readonly isExpired: boolean = false,
        readonly recurrenceRule: string | undefined,
        readonly isFirstRecurrence: boolean | undefined,
        readonly description: string,
        readonly location: string,
        readonly pdfs: Pdf[],
        readonly canEdit: boolean,
        readonly canMakeRecurring: boolean,
        readonly canMembersRegisterAttendance: boolean,
        readonly numberOfDaysFromInitialStart: number,
        readonly totalNumberOfDaysFromInitialStart: number
    ) {
        super()
    }

    getPromotionMessage(translations: TranslationFn): string {
        const description = stripMarkdown(this.description)
        switch (this.canMembersRegisterAttendance) {
            case true:
                return `${this.title}\n\n${translations('promote_attendance_message')}`
            default:
                return `${this.title}${description.length > 0 ? `\n\n${description}` : ''}`
        }
    }

    toSharedEvent(start?: DateTime): SharedEvent {
        return new SharedEvent(
            this.id,
            start || this.start,
            this.groupId,
            this.title,
            this.end,
            this.isAllDay,
            this.isExpired,
            false,
            this.totalNumberOfDaysFromInitialStart,
            this.canMembersRegisterAttendance
        )
    }

    static getDatesToExclude(params: {
        start: DateTime
        totalNumberOfDaysFromInitialStart: number
        numberOfDaysFromInitialStart: number
    }): DateTime[] {
        if (params.totalNumberOfDaysFromInitialStart === 0) {
            return [params.start]
        } else {
            const firstStart = params.start.minus({ days: params.numberOfDaysFromInitialStart })
            return range(0, params.totalNumberOfDaysFromInitialStart + 1).map((i) =>
                firstStart.plus({ days: i })
            )
        }
    }

    static fromJSON(response: Record<string, any>): Event {
        return new Event(
            response.id ?? response._id,
            response.parentId,
            response.title,
            response.group,
            DateTime.fromISO(response.initialStart),
            DateTime.fromISO(response.initialEnd),
            DateTime.fromISO(response.start),
            DateTime.fromISO(response.end),
            response.isAllDay,
            response.isExpired || false,
            response.recurrenceRule,
            response.isFirstRecurrence,
            response.description || '',
            response.location || '',
            response.pdfs.map((pdf: any) => Pdf.fromResponse(pdf)),
            response.canEdit,
            response.canMakeRecurring,
            response.canMembersRegisterAttendance,
            response.numberOfDaysFromInitialStart,
            response.totalNumberOfDaysFromInitialStart
        )
    }
}
